.awesomeSection {
    text-align: center;
    padding: 50px 0;
}

.sectionTitle {
    font-size: 2rem;
    margin-bottom: 40px;
}

.highlight {
    color: #ff1493; /* Pink highlight */
}

.featuresContainer {
    display: flex;
    justify-content: space-around;
    gap: 20px;
}

.feature {
    max-width: 300px;
    text-align: center;
}

.featureImage img {
    border-radius: 50%;
    width: 100%;
    height: auto;
}

.featureTitle {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 20px;
}

.featureDescription {
    font-size: 1rem;
    color: #666;
    margin-top: 10px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .featuresContainer {
        flex-direction: column;
        align-items: center;
    }

    .feature {
        margin-bottom: 20px;
        max-width: 100%; /* Ensures features take up the full width on mobile */
    }
}
