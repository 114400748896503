.header {
    position: sticky;
    top: 0px;
    z-index: 999999;
    width: 100%;
    /* padding: 10px 0px; */
    background-color: white;
    border-radius: 73px;
    border: 1px solid #efefef;
    width: 1200px;
    margin: auto;
    background: #fff;
    transition: transform 0.3s ease-in-out;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.header.visible {
    transform: translate3d(0, 10px, 0);
}

.header.hidden {
    transform: translate3d(0, -100%, 0);
}
.logo {
    width: 100px;
}
.logo p {
    color: var(--main);
}
.logo img {
    /* width: 70px; */
    height: 60px;
}
.header ul {
    display: flex;
    margin: 0px;
}

.navButton a {
    color: white !important;
    background-color: var(--main);
    border-radius: 50px;
    font-weight: bold;
    padding: 10px 30px !important;
    cursor: pointer;
    transition: 0.3s;
}
.landingButton {
    color: white !important;
    background-color: var(--main);
    border-radius: 50px;
    font-weight: bold;
    padding: 10px 30px !important;
    cursor: pointer;
    transition: 0.3s;
    margin: 0px;
    font-size: 16px;
}
.landingList li {
    height: auto !important;
}
.navButton a:hover {
    background-color: #0b9c7f;
}
.header a {
    color: black;
    font-weight: 600;
    transition: 0.3s;
    font-size: 15px;
}
.header a:hover {
    color: var(--main);
}
.header ul li {
    padding: 0px 20px;
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
}
.mobileNav {
    display: none;
}
.mobileDisplay {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.mobileList {
    display: flex;
    align-items: center;
}
.mobileList li {
    list-style: none;
}
.mobileDisplay a {
    z-index: 3;
}
.hamburger-menu {
    width: 20%;
    z-index: 3;
}
.mobileDisplay a {
    color: white;
}
.mobileNav ul {
    text-align: left;
}
.mobileMenuContainer {
    background-color: var(--main);
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: -1200%;

    transition: all 0.9s ease-in-out;
    -webkit-animation: movedown 0.9s linear forwards;
    -moz-animation: movedown 0.9s linear forwards;
    -ms-animation: movedown 0.9s linear forwards;
    -o-animation: movedown 0.9s linear forwards;
    animation: movedown 0.9s linear forwards;
    color: white;
    z-index: 99999;
    padding: 80px 20px;
}

.mobileMenuContainer.opened {
    top: 0px;
    transition: all 0.3s ease-in-out;
    overflow-y: hidden;
}
.mobileMenuContainer ul {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
}
.mobileMenuContainer a {
    font-size: 18px;
    font-weight: 400;
    color: white;
    text-align: left;
    animation: slidein 0.5s linear forwards;
    transition: all 0.2s ease-in-out;
}
.mobileHeaderData {
    display: flex;
    align-items: center;
    font-size: 13px;
}
.mobileBtn {
    display: block;
    text-align: center !important;
}

.hamburgerMenu {
    border-radius: var(--radius-input);
    color: var(--sub-main);
    margin-left: 5px;
    font-size: 20px;
    border-radius: var(--radius-input);
    outline: none;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.5s;
    z-index: 4;
}
.language {
    position: relative;
}
.languageList {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 25px;
    cursor: pointer;
    background-color: var(--white);
    width: 100%;
    border-radius: var(--radius-input);
    display: none !important;
}
.languageListMob {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 25px;
    cursor: pointer;
    background-color: var(--white);
    border-radius: var(--radius-input);
    display: none !important;
    left: -55%;
    z-index: 999;
}
.language:hover .languageList,
.language:hover .languageListMob {
    display: block !important;
}
.languageList li,
.languageListMob li {
    border-bottom: 1px solid #efefef;
    border-radius: var(--radius-input);
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 10px !important;
}
.languageActive {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.languageList li:hover,
.languageListMob li:hover {
    background-color: #efefef;
}
.languageList img,
.languageListMob img,
.languageActive img {
    width: 25px;
    margin-right: 5px;
}
.navBtn {
    border: 2px solid var(--dark-main);
    padding: 15px;
    border-radius: 50px;
}
.desktopHeader ul {
    width: 75%;
    justify-content: flex-end;
}

.hoverSecond {
    text-align: center !important;
}
.hoverSecond li {
    justify-content: center !important  ;
}
.hoverSecond a {
    font-size: 14px !important  ;
}
.desktopHeader div {
    width: 20%;
}
.desktopHeader a {
    font-size: 16px !important;
    font-weight: 700;
}
.desktopHeader {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.headerSignIn {
    display: flex;
    justify-content: flex-end;
}
.headerSignIn a:nth-last-child(1) {
    background-color: var(--main);
    color: white;
}
.headerSignIn a {
    padding: 10px 25px;

    transition: 0.3s;
    border-radius: 4px;
}
.headerSignIn a:nth-last-child(1):hover {
    background-color: #ffbbaa;
}
/* .desktopHeader div {
  width: 30%;
} */
.hoverOne img {
    height: 40px;
}
.hoverOne,
.hoverTwo {
    width: 100% !important;
    border-radius: 00px 0px 20px 20px;
    border-right: 1px solid #efefef;
    border-left: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    position: absolute;
    z-index: 999999;
    background: #fff;
    top: 45px;
    padding: 10px;
    /* display: flex; */
    left: 0;
    flex-direction: column;
    display: none !important;
}
.mobileClubs a {
    display: flex;
    align-items: center;
    gap: 10px;
}
.firstDropdown p,
.secondDrop p {
    cursor: pointer;
    font-weight: 700;
}
.firstDropdown:hover .hoverOne {
    display: flex !important;
}
.hoverMob {
    padding-top: 0px !important;
    display: none !important;
}
.hoverMob a {
    font-size: 16px !important  ;
}
.hoverMob li {
    padding-bottom: 0px;
    padding-top: 10px;
}
.activeMob {
    display: flex !important;
}
.firstDropdown:hover p img {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}
.secondDrop img {
    filter: invert(100%);
    height: 15px;
}
.activeRotate {
    transform: rotate(450deg) !important;

    transition: transform 0.3s ease-in-out !important;
}
.header .firstDropdown:nth-child(2):hover .hoverTwo {
    display: flex !important;
}
.noRotate {
    transform: rotate(360deg);
    transition: transform 0.3s ease-in-out;
}
.activeHover {
    display: flex !important;
}
.hoverOne li:nth-child(1) {
    border-bottom: 1px solid hsl(0, 0%, 53%, 0.1);
    padding-bottom: 10px !important;
}
.hoverOne li:nth-child(2) {
    padding-top: 10px !important;
}
.hoverOne li,
.hoverTwo li {
    padding: 0px !important;
}
.hoverOne div {
    width: 100% !important;
    padding-left: 10px;
    line-height: 10px;
}
.hoverOne a,
.hoverTwo a {
    display: flex;
    align-items: center;
}
.secondDrop p {
    font-size: 24px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.hoverOne p,
.hoverTwo p {
    font-weight: 700;
    font-size: 14px;
}
.colorOffer {
    color: white !important;
    font-weight: 300 !important;
}
.hoverTwo a {
    font-size: 14px !important;
}
.hoverTwo {
    width: 150px !important;
    text-align: center !important;
    left: -20px !important;
}
.activeHoverTwo {
    display: flex !important;
}

.hoverOne span {
    font-size: 8px;
    color: #878787;
}
.mobButton {
    background-color: #fbff4b;
    text-align: center;
    border-radius: 50px;
    padding: 10px;
}
.mobButton a {
    color: black;
}
.changeOffer {
    background-color: #fbff4b !important;
    color: black !important;
    z-index: 999999;
}
.nextOrder {
    display: block;
    color: white !important;
    font-size: 12px;
    text-align: center;
}
.changeOffer a {
    color: black;
}
.changeOffer span {
    color: black !important;
}
.removeVis {
    position: relative !important;
}
.schoolCamp {
    margin-top: 10px;
    display: none !important;
}
.schoolCamp a {
    display: flex;
    flex-direction: column;
    background-image: url('../../../public/image/bl.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 5px;
    border-radius: 8px;
}

.schoolCamp span {
    background-image: url('../../../public/image/green.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    padding: 4px;
}
.schoolCamp p,
.schoolCamp h3,
.schoolCamp span {
    margin: 0px;
    color: white;
}
.schoolCamp h3 {
    font-size: 16px;
}
.schoolCamp p,
.schoolCamp span {
    font-size: 10px;
}
.schoolCamp p {
    font-style: italic !important;
}
.schoolCamp {
    height: fit-content !important;
    padding: 10px 0px !important;
}
.spookySeason {
    background-color: #042b09;
    background-image: url('../../../public/image/bck.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center !important;
    margin-top: 20px;
    position: relative;
}
.spookySeason span {
    background-color: var(--sub-main);
    padding: 2px;
    color: white;
    position: absolute;
    top: 0px;
    left: 0px;
}
.spookySeasonBox li {
    height: fit-content !important;
    justify-content: center;
}
.spookySeason h3 {
    font-size: 16px;
    color: #fbff4b;
}
@media (max-width: 1024px) {
    .desktopHeader ul {
        width: 100%;
    }
    .desktopHeader div {
        width: 20%;
    }
    .header {
        width: 90%;
    }
}
@media (max-width: 800px) {
    .mobileNav {
        display: block;
    }
    .desktopHeader {
        display: none;
    }
    .logo {
        width: 100%;
        padding: 0px;
    }
    .mobileMenuContainer a {
        font-size: 20px;
        font-weight: 900;
    }
    .logo img {
        height: 80px;
    }
    .header {
        width: 90%;
    }
    .mobileClubs span {
        font-size: 14px;
    }
    .mobileClubs p {
        font-weight: 800;
        height: 20px;
    }
    /* .headerMobile {
        background-color: var(--sub-main);
    } */
    .mobileClubs img {
        width: 50px;
    }
    .summerMob {
        display: none !important;
    }

    .summerMob a {
        background-image: url('../../../public/image/green.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 8px;
    }

    .summerMob span {
        background-color: #68bef9;
        padding: 4px;
        font-size: 14px;
    }
    .summerMob h3 {
        color: white;
        font-size: 18px;
        margin: 0px;
        padding: 10px 0px;
    }
    .spookySeason {
        padding-top: 0px !important;
        position: relative;
    }
    .spookySeason h3 {
        font-size: 18px !important;
    }
    .mobileMenuContainer {
        padding-top: 120px;
    }
}
