.errorMsg {
    position: relative;
}
.errorBanner,
.mobError {
    width: 100%;
}
.errorMsg div {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: absolute;
    bottom: 100px;
    left: 0px;
    right: 0px;
}
.errorMsg h1 {
    color: white;
}
.errorMsg p {
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
}
.mobError {
    display: none;
}
@media (max-width: 800px) {
    .errorBanner {
        display: none;
    }
    .mobError {
        display: block;
    }
    .errorMsg div {
        bottom: 10px;
    }
    .errorMsg p {
        font-size: 13px;
        padding-bottom: 10px;
    }
}
