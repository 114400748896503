.plansCollage {
    width: 55%;
}

.plansBox {
    width: 45%;
}
.plans {
    padding: 50px 0px;
}
.plansCollage {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.plansCollage div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    column-gap: 20px;
    row-gap: 20px;
}
.plansCollage img {
    width: 100%;
}
.plans {
    display: flex;
    align-items: flex-start;
    width: 90%;
    margin: auto;
    gap: 50px;
}
.plansBck {
    background-color: #fff7ef;
    position: relative;
}

.plansMobile {
    display: none;
}
.plansMobile img {
    width: 100%;
    border-radius: 10px;
}
.plansMobile {
    width: 100%;
}
@media (max-width: 800px) {
    .plans {
        flex-direction: column;
        width: 100%;
        gap: 0px;
        padding-bottom: 0px;
    }
    .plansBox,
    .plansCollage {
        width: 100%;
    }
    .plansCollage {
        display: none;
    }
    .plansMobile {
        display: block;
        padding-bottom: 50px;
    }
    .addToCart {
        width: 90%;
    }
}
