.kidTestimonialsBox div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}
.kidTestimonialsBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    column-gap: 5px;
}
.kidTestimonialsBox img {
    width: 100%;
}
.kidTesti {
    text-align: center;
}
.kidTesti span {
    color: #e95242;
}
.kidTesti {
    margin-bottom: -100px;
}
@media (max-width: 800px) {
    .kidTesti {
        padding-top: 0px !important;
    }
    .kidTesti p,
    .kidTesti h1 {
        width: 90%;
        margin: auto;
    }
    .kidTesti h1 {
        font-size: 30px;
    }
    .kidTesti span {
        font-size: 35px !important;
    }
}
