.borderColor img,
.fillColor img,
.fillCubColor img,
.fillZooColor img {
    height: 10px !important;
    margin-left: 10px;
}
.fillColor,
.borderColor,
.fillCubColor,
.fillZooColor {
    display: flex;
    align-items: center;
    border-radius: 50px;
    padding: 10px 15px;
    font-weight: bold;
    font-size: 18px;
}
.fillColor {
    background: #fbff4b;
    transition: 0.3s;
}
.borderColor img,
.fillColor img {
    background-color: #e2e23d;
    width: 30px;
    height: 30px !important;
    padding: 9px;
    border-radius: 50px;
}
.borderColor,
.fillColor {
    padding: 4px 5px;
    padding-left: 10px;
}
.fillColor:hover {
    background: #0b9c7f;
    color: white;
}
.fillColor:hover img {
    /* filter: invert(100%); */
    transition: 0.3s;
    background-color: #e2e23d !important;
}
.yellow {
    filter: invert(0%) !important;
}
.borderColor {
    border: 1px solid #fbff4b;
    color: #fbff4b;
    transition: 0.3s;
}
.borderColor:hover {
    border: 1px solid #0b9c7f;
    color: white;
    background-color: #0b9c7f;
}
.borderColor:hover img {
    /* filter: invert(100%); */
}
.borderColor img {
    fill: #fbff4b !important;
    transition: 0.3s;
}
.fillCubColor {
    background-color: #ea5443;
    color: white;
    transition: 0.3s;
}
.fillCubColor:hover {
    background-color: #0b9c7f;
}
.fillCubColor img {
    filter: invert(100%);
}
.fillZooColor {
    background-color: var(--sub-main);
    color: white;
    transition: 0.3s;
}
.fillZooColor:hover {
    background-color: #0b9c7f;
}
.fillZooColor img {
    filter: invert(100%);
}
@media (max-width: 800px) {
    .fillCubColor img,
    .fillZooColor img {
        height: 10px !important;
        width: 10px !important;
    }

    .borderColor img,
    .fillColor img {
        height: 30px !important;
        width: 30px !important;
    }
}
