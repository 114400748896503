.stepsContainer {
    position: relative;
    background-color: white;
    width: 650px;
    padding: 35px 20px;
    border: 1px solid #359597;
    border-radius: 20px;
    margin: auto auto;
}
.formBck {
    background-image: url('../../../public/image/zoo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    background-color: #71b4a3;
    height: 140vh;
    max-height: 100%;
    width: 100%;
}
.stepsBtn {
    padding: 15px 20px;
    background-color: #d9d9d9;
    border: none;
    color: white;
    font-size: 20px;
    border-radius: 50px;
    cursor: pointer;
    width: 100%;
    transition: 0.3s;
    margin-top: 10px;
    text-align: center;
}
.addName {
    font-size: 18px;
    line-break: anywhere;
    width: 120px;
    /* padding-left: 15px; */
    margin: auto;
    transform: rotate(0deg);
}
.StepOneInput div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
}
.StepOneInput input {
    width: 35%;
    padding: 20px 25px;

    border: 1px solid #ccc;
    border-radius: 12px;
}
.stepsHeading,
.plansHeading {
    padding-bottom: 20px;
    width: 75%;
}
.stepsHeading h2,
.plansHeading h2 {
    color: #258d8f;
}
.StepOneInput span {
    padding-top: 5px;
    font-size: 12px;
}
.boxName {
    position: relative;
    width: 180px;
}
.boxPos {
    position: absolute;
    right: 0;
    top: -60px;
}

.koala {
    position: absolute;
    top: -65px;
    left: 20px;
}
.girafe {
    position: absolute;
    right: -181px;
    bottom: 80px;
}
.orongutan {
    position: absolute;
    top: -83px;
}
.boxName div {
    position: absolute;
    bottom: 0;
    top: 0px;
    font-size: 12px;
    text-align: center;
    right: 0;
    left: 0;
    right: 0;
    background: white;
    transform: rotate(-3deg);
    width: 150px;
    margin: auto;
    height: fit-content;
    min-height: 60px;
    max-height: 300px;
    border-radius: 8px;
}
.boxName p {
    font-size: 12px;
    transform: rotate(0deg);
    padding-top: 5px;
}
.boxName img {
    height: 180px;
}
.error {
    color: red;
    padding-left: 5px;
    padding-top: 0px !important;
}
/*Club*/
.cubDis img {
    height: 100px;
    cursor: pointer;
}
.cubDis input {
    display: none;
}
.cubDis label:nth-child(1) {
    padding-right: 20px;
}
.stepClub {
    padding-top: 20px;
}
/*Email*/
.emailInput input {
    width: 75%;
    padding: 20px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 12px;
}
.emailInput {
    display: flex;
    flex-direction: column;
}
.policy {
    padding-top: 20px;
    font-weight: 100;
    width: 75%;
}
.plan {
    background-repeat: no-repeat;
    border: 0.654px solid rgba(77, 77, 77, 0.2);
    border-radius: 5.236px;
    background-size: cover;
    width: 100%;
    position: relative;
    /* color: white; */
}
.plan label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    cursor: pointer;
}
.colorPlan {
    display: flex;
    align-items: center;
    padding: 25px 10px;
    background: #d6f2e5;
}
.planDis {
    display: flex;
    align-items: center;
}
.planDis div {
    display: flex;
    flex-direction: column;
    text-align: left;

    /* padding-left: 20px;
    padding-right: 20px; */
}
.planSave {
    border-radius: 40px;
    background: #ea5a4b;
    width: 100px;
    text-align: center;
    position: absolute;
    top: -10px;
    color: white !important;
    padding: 2px;
    font-weight: bold;
}
.comb {
    display: flex !important;
    flex-direction: row !important;

    align-items: center;
    padding-left: 0px !important;
}
.planDis p {
    font-size: 12px;
}
.comb img {
    height: 20px;
    margin: 0px 10px;
}
.plan input {
    width: 30px;
    height: 20px;
}
.plan h2 {
    font-size: 16px;
    color: #4d4d4d !important;
}
.colorPlan h2 {
    width: 80px !important;
}
.plan {
    margin-bottom: 20px;
}
/*Siblings*/
.siblings,
.addon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}
.siblings h3 {
    font-size: 18px;

    color: #258d8f;
    text-align: center;
}

.siblings h1,
.addon h1 {
    font-size: 24px;
    color: #258d8f;
}
.siblings p {
    font-size: 14px;
    padding-bottom: 20px;
}
.sibButton,
.buttonDis {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
}
.sibContent {
    width: 50%;
}
.sibContent p {
    padding: 10px 0px;
    font-size: 12px;
    color: #505050;
}
.sibContent h3 {
    margin: 0px;
}
.sibImage {
    width: 50%;
}
.cartSibBtn {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100% !important;
}
.cartSibBtn p {
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 12px;
    cursor: pointer;
}
.sibButton div {
    border: 1px solid #359597;
    border-radius: 50px;
    text-align: center;
    padding: 10px;
    cursor: pointer;
}
.sibButton h3,
.sibButton p {
    margin: 0px;
    padding: 0px;
    cursor: pointer;
}
.sibButton div p {
    font-size: 12px;
    color: #7a7a7a;
}
.noThanks {
    padding-top: 10px !important;
}
.sibImage,
.sibContent {
    width: 100%;
}
.sibImage img {
    width: 100%;
}
/*Addon*/

.buttonDis {
    gap: 10px;
    padding-top: 20px;
}
.buttonDis button:nth-child(1) {
    background-color: #f0c161;
    color: white;
    font-size: 18px;
    padding: 15px 20px;
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
    border: unset;
}
.buttonDis button:nth-child(2) {
    border: 1px solid #159888;
    color: #159888;
    background-color: unset;
    font-size: 18px;
    padding: 15px 20px;
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
}
.stepNum {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 100px;
    gap: 40px;
}
.back {
    background-color: #359597;
    color: white;
    position: absolute;
    bottom: -41px;
    padding: 10px;
    cursor: pointer;
    border-radius: 0px 0px 20px 20px;
}
@media (max-width: 800px) {
    .stepsContainer,
    .emailInput input,
    .policy {
        width: 100%;
    }
    .StepOneInput div {
        flex-direction: column;
    }
    .StepOneInput input {
        width: 100%;
    }
    .boxPos img {
        height: 120px;
    }
    .boxName div {
        top: 0px;
        height: fit-content;
        min-height: 60px;
        max-height: 283px;
        width: 95px;
    }
    .boxName p {
        font-size: 10px;
        padding-top: 5px;
    }
    .boxName {
        width: 120px;
    }
    .addName {
        font-size: 12px;
        width: 80px;
    }
    .stepsHeading {
        width: 90%;
    }
    .plan label {
        gap: 10px;
    }
    .comb img {
        display: none;
    }
    .comb h2 {
        font-size: 12px;
    }
    .comb p {
        font-size: 12px;
        color: #ea5443 !important;
        font-weight: 100;
    }
    .colorPlan {
        padding: 25px 0px;
    }
    .comb {
        padding-bottom: 5px;
    }
    .siblings,
    .addon {
        flex-direction: column;
    }
    .formBck {
        height: 170vh;
    }
    .stepNum img {
        width: 55px;
        height: 55px;
    }
    .stepNum {
        gap: 10px;
        /* padding-bottom: 50px; */
    }
}
