.hubContent {
    background-color: #ea5443;
    width: 45%;
    border-radius: 20px;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.hubContent img {
    height: 50px;
}
.hubDis {
    display: flex;
    padding-bottom: 20px;
}
.reverse {
    flex-direction: row-reverse;
}
.reverse .hubContent {
    background-color: var(--sub-main);
}
.hubImage {
    width: 55%;
}
.hubImage img {
    width: 100%;
    border-radius: 20px;
}
.hubContent h3 {
    padding: 20px;
    border-top: 1px solid hsl(0, 0%, 100%, 0.5);
    border-bottom: 1px solid hsl(0, 0%, 100%, 0.5);
    font-size: 24px;
}
.hubContent span {
    font-size: 14px;
    font-weight: 300;
}
.hubContent a {
    color: var(--sub-main);
    background-color: white;
    border-radius: 50px;
    padding: 8px;

    font-weight: bold;
    width: 90%;
    margin: 0px auto;
    margin-top: 20px;
}
.btnColor {
    color: #ea5443 !important;
}
.hubContent b {
    padding-top: 20px;
}
.hubHeader h3 {
    background-color: var(--main);
    padding: 5px 20px;
    border-radius: 50px;
    text-align: center;
    color: white;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    width: fit-content;
    margin: 0px;
}
.hubContent p {
    padding-top: 20px;
    font-weight: 700;
}
.hubHeader {
    width: 70%;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px;
}
.hubHeader span {
    color: var(--sub-main);
}
.hubHeader p {
    padding-bottom: 20px;
    font-weight: 500;
}
.hubHeader a {
    text-decoration: underline;
}
.hubNav {
    background-color: #ff8c4b;
    padding: 10px;
    padding-top: 20px;
    margin-top: -15px;
    border-radius: 0px 0px 75px 95px;
    text-align: center;
    color: white;
    width: 1100px;
    margin: auto;
}
.hubNav p {
    font-weight: 700;
}
.hubNav a {
    color: black;
    font-weight: 700;
    background-color: #fbeb5b;
    border-radius: 50px;
    padding: 2px 5px;
    cursor: pointer;
    margin: 0px 5px;
}
.hubBck {
    background-color: #fbf4eb;
}
@media (max-width: 800px) {
    .hubDis {
        flex-direction: column;
    }
    .hubBck {
        padding-top: 20px;
    }
    .hubNav {
        width: 90%;
        margin: auto;
        border-radius: 50px;
        line-height: 24px;
        padding: 10px 20px;
    }
    .hubNav p {
        font-size: 14px;
    }
    .hubContent,
    .hubImage {
        width: 100%;
    }
    .hubContent {
        border-radius: 20px 20px 0px 0px;
    }
    .hubHeader {
        width: 100%;
    }
    .hubHeader h3 {
        font-size: 16px;
    }
}
