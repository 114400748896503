.giftMyZoobck {
    /* background-color: #e5af7880; */
    background-image: url('../../../public/image/bckColor.webp');
    background-repeat: repeat;
}
.giftMyZoobck h1,
.giftMyZoobck p {
    color: white;
}
.giftMyZoobck p {
    font-weight: 500;
}
.giftZooDis div:nth-child(1) {
    background-color: #268d8f;
    border: 5px solid #7ce0e2;
    color: white;
}
.giftZooDis div:nth-child(3) {
    background-color: #e95242;
    border: 5px solid #ffab9f;
    /* border: 5px solid #ffed82; */
    color: white;
}
.giftZooDis div:nth-child(2) {
    background-color: #f9cc0b;
    border: 5px solid #ffed82;
}

.giftZooDis div:nth-child(2) p,
.giftZooDis div:nth-child(2) h3 {
    color: black;
}
.giftZooDis {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    column-gap: 50px;
    padding-top: 150px;
}
.giftZooDis div {
    border-radius: 100%;
    position: relative;
    width: 100%;
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    /* height: 200px; */
    padding: 10px 30px;
}
.giftZooDis img {
    position: absolute;
    width: 160px;
    height: 160px;
    top: -100px;
}
.giftZooDis h3 {
    margin: 0px;
    font-size: 24px;
}
@media (max-width: 800px) {
    .giftZooDis {
        grid-template-columns: repeat(1, 1fr);
        gap: 120px;
    }
    .giftZooDis div {
        height: 315px;
    }
}
