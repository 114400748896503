.banner {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    background-image: url('../../../public/image/gift.webp');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    margin-bottom: -50px;
    z-index: 1;
    position: relative;
}
.banner div:nth-child(1) {
    width: 45%;
    /* background-color: var(--sub-main); */
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 120px 50px;
}

.banner div:nth-child(2) {
    display: flex;
    align-items: center;
    width: 55%;
}
.banner h1 {
    font-size: 38px;
    color: white;
}
.banner p {
    color: white;
    font-size: 15px;
    padding: 20px 0px;
}
.banner img {
    height: 400px;
}
.buttonDis {
    display: flex;
    align-items: center;
}
.buttonDis a {
    margin: 5px;
}
.cubIcon {
    height: 50px !important;
    width: 50px !important;
}
.zooTitle {
    color: var(--sub-main);
}
.giftEasy {
    display: flex;
    /* align-items: center; */
    gap: 50px;
    padding-bottom: 50px;
}
.giftEasy img {
    width: 100%;
    height: fit-content;
    border-radius: 10px;
}
.easyImg,
.easyContent {
    width: 50%;
    text-align: left;
}
.easyBck {
    background-color: #fff7ef;
    padding-top: 50px;
}
.easyContent div {
    display: flex;
    align-items: center;
    padding-top: 30px;
}
.easyContent p {
    padding-left: 15px;
    font-size: 18px;
}
.easyContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.easyContent span {
    padding: 20px;
    width: 25px;
    height: 25px;
    font-size: 24px;
    font-weight: bold;
    color: white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.easyContent div:nth-child(2) span {
    background-color: #347bc1;
}
.easyContent div:nth-child(3) span {
    background-color: #9fdf66;
    margin-left: 15px;
}
.easyContent div:nth-child(4) span {
    background-color: #fc686c;
    margin-left: 30px;
}
@media (max-width: 800px) {
    .banner div:nth-child(1) {
        width: 100%;
        padding: 20px;
        justify-content: flex-start;
    }
    .giftEasy {
        flex-direction: column;
        padding-bottom: 50px;
    }
    .giftEasy div {
        width: 100%;
    }
    .banner {
        background-image: url('../../../public/image/mobb.webp');
        height: 600px;
        /* object-position: bottom; */
        background-position: bottom;
    }
    .banner h1 {
        font-size: 30px;
    }
}
