.summerBanner img {
    width: 100%;
}
.summerBanner img:nth-child(1) {
    display: block;
}
.summerBanner img:nth-child(2) {
    display: none;
}
.summerBanner {
    cursor: pointer;
}
.summerWorks {
    text-align: center;
    border-radius: 0px 0px 50px 50px;
}
.summerDis {
    display: grid;
    column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
}
.summerWorks h2 {
    padding-bottom: 20px;
    font-size: 32px;
}
.summerDis h3 {
    margin: 0px;
}
.summerDis div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.expeditionHeader div p {
    font-weight: 700;
    font-size: 18px;
}
.summerWorks {
    background-color: #c5ecf4;
    padding: 50px;
}
.summerWorks p {
    width: 85%;
    margin: auto;
    padding-top: 10px;
}
.expeditionHeader {
    text-align: center;
}
.expeditionHeader div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: var(--sub-main);
    margin: auto;
    margin-top: 50px;
    width: fit-content;
    padding: 0px 20px;
    border-radius: 8px;
    position: relative;
    height: 45px;
    padding-left: 50px;
    margin-bottom: 20px;
}
.expeditionHeader span {
    font-size: 12px;
    font-weight: bold;
}
.campIcon {
    position: absolute;
    left: -10px;
}
.zooIcon {
    height: 20px;
    width: 40px;
}
.expeditionHeader div p {
    margin: 0px !important ;
    width: fit-content !important;
    font-size: 24px;
    color: white;
}
.expeditionHeader img {
    height: 60px;
}
.expeditionHeader h2 {
    font-size: 38px;
    font-weight: bold;
    color: #ea5443;
}
.expeditionHeader p {
    font-weight: 600;
    width: 40%;
    margin: auto;
}
.expedition {
    /* background-color: #fff7ef; */
    padding: 50px 0px;
}
.shopLetter {
    padding-top: 100px;
}
.experienceBck {
    padding: 50px 0px;
}
.summerContainer {
    background-color: #fff7ef;
    padding-bottom: 100px;
}
.experienceHeader {
    text-align: center;
}
.experienceHeader h1 {
    color: var(--sub-main);
}
.experienceHeader p {
    font-weight: 500;
    font-size: 18px;
}

.summerDis img {
    height: 150px;
}

.campImage {
    background-image: url('../../../public/image/rainCamp.webp');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 47px;
    gap: 20px;
    width: 50%;
    position: relative;
}
.oceanImage {
    background-image: url('../../../public/image/oceanBck.webp');
}
.lmOut {
    height: 150px;
    position: absolute;
    right: 0;
    bottom: 90px;
}
.campImage a {
    display: block;
    width: fit-content;
    margin: auto;
    z-index: 999;
    background-color: #fbff4b;
    border-radius: 50px;
    padding: 10px 15px;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: 0.3s;
}
.campImage a:hover {
    background-color: var(--sub-main);
    color: white;
}
.campImage a:hover img {
    filter: invert(100%);
}
.campImage a img {
    height: 12px;
    transition: 0.3s;
}
.campBadge {
    height: 80px;
    width: 80px;
    margin: auto;
}
.campTitle {
    height: 150px;
}
.campBoxes {
    background-color: #315722;
    padding: 10px 30px;
    border-radius: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
}
.oceanBoxes {
    background-color: #1f71ab;
}
.campDis {
    display: flex;
    /* align-items: center; */
}
.expeditionBoxes {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.campBoxOne,
.campBoxTwo,
.campBoxThree {
    display: flex;
    align-items: center;
    margin-right: -20px;
    width: fit-content;
    padding-right: 10px;
    padding-left: 10px;
    margin: 0px auto;
    justify-content: space-between;
}
.campBoxOne img:nth-child(1),
.campBoxTwo img:nth-child(1),
.campBoxThree img:nth-child(1) {
    height: 110px;
    margin-left: -20px;
    z-index: 9;
}

.oceanBoxes .campBoxOne img:nth-child(1),
.oceanBoxes .campBoxTwo img:nth-child(1),
.oceanBoxes .campBoxThree img:nth-child(1) {
    height: 120px !important;
}
.campBoxOne img:nth-child(2),
.campBoxTwo img:nth-child(2),
.campBoxThree img:nth-child(2) {
    width: 130px;
    margin-top: -10px;
}
.campBoxOne img:nth-child(2),
.campBoxTwo img:nth-child(2) {
    margin-left: -40px;
}
.campBoxThree img:nth-child(2) {
    margin-left: -80px;
}
.campBoxOne {
    background-color: #297317;
}
.campBoxTwo {
    background-color: #87d473;
}
.campBoxThree {
    background-color: #448b29;
}
.campBoxTwo,
.campBoxThree,
.campBoxOne {
    height: 75px;
    width: 100%;
    border-radius: 20px;
}

.campContainer {
    width: 650px;
    margin: auto;
}
.oceanBoxes .campBoxThree img:nth-child(2) {
    margin-left: 0px !important;
}
.summerTab {
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    background-color: var(--sub-main);
    border-radius: 50px;
    padding: 10px 20px;
    margin: auto;
    cursor: pointer;
}
.summerTab p {
    color: white;
}
.summerTab img {
    filter: invert(100%);
    height: 15px;
}
.oceanBoxes .campBoxOne {
    background-color: #203b83;
}
.oceanBoxes .campBoxTwo {
    background-color: #91d3ff;
}
.oceanBoxes .campBoxThree {
    background-color: #96e1e1;
}
.bottomLabel {
    padding-top: 20px;
    font-weight: 600;
    text-align: center;
}

.edventureBox {
    display: flex;
    align-items: center;
}
.edventure img {
    height: 160px;
}
.edventure h3 {
    margin: 0px;
}
.edventureBox div {
    padding-left: 20px;
}
.edventure {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    column-gap: 20px;
    row-gap: 20px;
    justify-content: center;
}

.rotate {
    transition: transform 0.3s ease; /* Add transition property for smooth animation */
}

.rotate.active {
    transform: rotate(
        90deg
    ); /* Apply rotation when the 'active' class is present */
}
.summerTab p {
    text-align: center;
    font-weight: 700;
}
.campBoxes h3 {
    color: white;
    margin: 0px;
    text-align: center;
}

.campPrice {
    color: white;
    text-align: center;
}
.campPrice div {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}
.campPrice span {
    position: relative;
}
.campPrice span::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px; /* Adjust thickness of line-through */
    background-color: red; /* Adjust color of line-through */
    transform-origin: center;
}

.campPrice span::after {
    transform: rotate(-15deg); /* Adjust rotation angle */
}
.campPrice p {
    font-size: 14px;
    margin: 0px;
    font-weight: 500;
}
.campPrice h2 {
    font-size: 32px;
}
@media (max-width: 800px) {
    .summerBanner img:nth-child(2) {
        display: block;
    }
    .campContainer {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    .summerBanner img:nth-child(1) {
        display: none;
    }
    .experienceDis,
    .summerDis {
        grid-template-columns: repeat(1, 1fr);
    }
    .experienceClubImg {
        height: auto;
    }
    .campImage,
    .campBoxes {
        width: 100%;
        padding: 30px;
        /* border-radius: 0px 0px 20px 20px; */
    }
    .campImage {
        border-radius: 20px 20px 0px 0px;
    }
    .campBoxes {
        border-radius: 0px 0px 20px 20px;
    }

    .campDis {
        flex-direction: column;
    }
    .campBoxOne,
    .campBoxTwo,
    .campBoxThree {
        width: 92%;
    }
    .expeditionHeader p {
        width: 100%;
    }
    .summerTab {
        width: 90%;
        font-weight: 900;
    }

    .edventure,
    .guideDis {
        grid-template-columns: repeat(1, 1fr);
    }
    .edventure img {
        height: 120px;
    }
    .bookDis {
        align-items: flex-start;

        flex-direction: column;
    }
    .edventureBox {
        flex-direction: column;
        align-items: flex-start;
    }
    .edventure {
        gap: 50px;
    }
    .edventureBox div {
        padding-left: 0px;
    }
    .campTitle {
        object-fit: contain;
    }
    .campBadge {
        display: none;
    }
    .summerContainer {
        padding-bottom: 50px;
    }
    .campImage {
        padding: 50px 30px;
    }
}
