.contactHeader {
    width: 100%;
    position: relative;
}
.contactHeader img {
    width: 100%;
}
.contactHeader h1 {
    position: absolute;
    color: white;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
}
.contactBck {
    background-color: #e95242;
    padding: 50px 0px;
    padding-bottom: 250px;
}
.error {
    font-size: 12px;
    text-align: left;
    color: red !important;
}
.contactDis {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
}
.contactDis form {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
}
.contactDis button {
    background-color: #ffe268;
    color: black;
    border: unset;
    padding: 10px 20px;
    border-radius: 8px;
    width: fit-content;
    margin-top: 20px;
    cursor: pointer;
    transition: 0.3s;
}
.contactDis form div {
    display: flex;
    flex-direction: column;
}
.contactDis button:hover {
    background-color: #258d8f;
    color: white;
}
.contactDis input {
    background-color: #fafafa;
    border: unset;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}
.contactDis textarea {
    max-width: 100%;
    min-width: 100%;
    min-height: 200px;
    max-height: 300px;
}
.contactFormBck {
    margin-top: -180px;
}
.contactDis p,
.contactDis h3 {
    color: white;
}
.contactFullBck {
    background-color: #fff8ef;
    padding-bottom: 50px;
}
.socials div {
    display: flex;
    align-items: center;
    gap: 10px;
}
.showMessage {
    display: flex !important;
}
.hideMessage {
    display: none !important;
}
.socials h2 {
    padding-top: 40px;
    padding-bottom: 10px;
    font-size: 18px;
}
.submitMessage {
    text-align: center;
    padding: 100px 0px;
}
@media (max-width: 800px) {
    .contactDis {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 50px;
    }
    .contactFormBck {
        margin-top: -235px;
    }
}
