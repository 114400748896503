.planHeading {
    text-align: center;
}
.planToggle {
    width: 390px;
    margin: 0px auto;
    position: relative;
}

.planHeading img {
    height: 30px;
}
.planHeading span {
    color: #ea5443;
}
.planHeading h1 {
    font-size: 30px;
    padding-bottom: 10px;
}
.planHeading p {
    font-weight: 700;
    width: 100%;
    margin: auto;
    font-size: 14px;
}
.toggleContainer {
    margin: 20px;
    font-family: Arial, sans-serif;
}
.toggleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.overlay {
    background-color: hsl(0, 0%, 0%, 0.4);
    width: 100%;
    height: 100vh;
    right: 0;
    position: fixed;
    z-index: 999999;
    bottom: 0;
}
.choice span {
    font-size: 10px;
    background-color: #fbff4b;
    padding: 2px 10px;
    border-radius: 50px;
    font-weight: 700;
}
.choice p {
    font-weight: 700;
    text-align: center;
}
.toggleButton {
    position: relative;
    width: 60px;
    height: 30px;
    background-color: #ccc;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50px;
}

.circle {
    position: absolute;
    top: 0;
    left: 3px;
    right: 0;
    bottom: 0;
    margin: auto 0px;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    filter: drop-shadow(2px 1px 6px rgba(0, 0, 0, 0.25));
}
.giftToggle {
    text-align: center;
}
.giftToggle div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
    margin: auto;
}

.giftToggle p {
    padding: 10px;
    border: 1px solid #efefef;
    background: white;
    border-radius: 50px;
    width: 50%;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
}
.activeGift {
    border: 1px solid #ea5443 !important;
    background: #ea5443 !important;
    color: white;
}
.mainToggle h3 {
    text-align: center;
}
.toggled .circle {
    transform: translateX(30px);
    right: 5px;
}
.toggleBoxes {
    border-radius: 10px;
    border: 1px solid #efefef;
    /* padding-bottom: 15px; */
    background: #fff;
    position: relative;
}
.planToggDis {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin: auto;
    align-items: baseline;
    row-gap: 30px;
    padding-top: 25px;
}
.code {
    position: absolute;
    top: -28px;
    width: 95%;
    margin: auto;
    background-color: #0b9c7f;
    color: white;
    font-size: 14px;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    left: 0;
    right: 0;
    text-align: center;
}
.code span {
    color: #f9cc0b;
}
.toggleContent {
    background: #f6e7a6;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
}
.toggleContent p {
    /* padding-bottom: 20px; */
    font-size: 12px;
}
.toggleContent h3 {
    font-size: 18px;
    margin: 0px;
    font-weight: 700;
    padding-top: 10px;
}

.toggleContent h2 {
    padding-top: 10px;
}
.toggleContent div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}
.toggleContent span {
    font-size: 12px;
    color: white;
    padding: 2px 5px;
}
.toggleContent span:nth-child(1) {
    border-radius: 10px;
    background: #0b9c7f;
}
.toggleContent span:nth-child(2) {
    border-radius: 10px;
    background: #ea5443;
}
.free {
    color: #ea5443;
    font-size: 12px;
    font-weight: 700;
    padding: 5px 0px 10px 0px;
}
.oneBox {
    font-size: 12px;
    font-weight: 700;
}
.openOption button {
    background-color: #ea5443;
    width: 100%;
    margin: auto;
    border: unset;
    border-radius: 50px;
    padding: 10px;
    color: white;
    cursor: pointer;
    transition: 0.3s;
    margin: 0px auto;
    width: 250px;
}
.colorBorder {
    border: 2px solid #258d8f;
    margin-top: 20px;
}
.openOption {
    width: 100%;
    padding: 0px 20px;
    margin: auto;
    height: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-evenly;
}
.openOption button:hover {
    background-color: #0b9c7f;
}

.showList {
    max-height: 1000px;
    opacity: 1;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.hideList {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.addBck {
    color: var(--sub-main) !important;
}
.planFooter {
    padding-top: 20px;
}
.planFooter p {
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 5px;
}
.insideInfo img {
    height: 15px;
    /* display: inline; */
    cursor: pointer;
}
.insideInfo p {
    font-size: 12px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    position: relative;
}
.planFooter div {
    display: flex;
    gap: 5px;
}
.planFooter div p {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
}
.infoText {
    position: absolute;
    background-color: #0b9c7f;
    padding: 5px;
    font-size: 14px;
    left: 0;
    /* top: 20px; */
    border-radius: 8px;
    color: white;
    text-align: left;
    z-index: 99999999;
    bottom: -20px;
    height: fit-content;
}
.planFooter span {
    font-size: 14px;
}
.goBack {
    cursor: pointer;
    color: #0b9c7f;
    padding-top: 20px;
    transition: 0.3s;
    font-weight: bold;
}
.goBack:hover {
    color: #fbff4b;
}
.colorOne {
    background-color: #ffddc7;
}
.colorTwo {
    background-color: #e1ffc9;
}
.colorThree {
    background-color: #ffe4e1;
}
.showText {
    display: block;
}
.hideText {
    display: none;
}
.toggled {
    background-color: #ea5443;
}
.totalPrice {
    font-weight: bold;
    color: #696969;
}

.addToCart {
    flex: 1;
    padding: 20px;
}
.addToCart {
    position: fixed;
    top: 0%;
    right: 0;
    height: 100vh;
    width: 500px;
    transform: translateY(0%);
    background-color: white;
    padding: 20px;
    padding-top: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-out; /* Add transition for smooth movement */

    /* Additional styles */
    z-index: 99999999; /* Ensure it's on top of other content */
}

.addToCart.hidden {
    transform: translateX(100%); /* Move it out of the screen when hidden */
}
.addToCartButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.addToCartButton:hover {
    background-color: #0056b3;
}
.cartSib {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding-bottom: 20px;
}
.sibImage img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    object-position: top;
}
.sibContent {
    width: 75%;
}
.sibContent p {
    padding: 10px 0px;
    font-size: 12px;
    color: #505050;
}
.sibContent h3 {
    margin: 0px;
}
.sibImage {
    width: 25% !important;
}
.cartSibBtn {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100% !important;
}
.cartSibBtn p {
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 12px;
    cursor: pointer;
}
.cartHeading {
    text-align: center;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #eaeaea;
    padding: 20px 0px;
}
.checkoutBtn button {
    background-color: #ea5443;
    color: white;
    width: 100%;
    padding: 10px;
    font-weight: bold;
    border: unset;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.cartHeading img {
    position: absolute;
    bottom: 0px;
    top: 0;
    height: 30px;
    left: -15px;
    margin: auto;
    cursor: pointer;
}
.cartSibBtn p {
    border: 1px solid #0b9c7f;
    color: #0b9c7f;
    display: flex;
    align-items: center;
    gap: 5px;
}

.selectedBox {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
}
.selectedBox img {
    width: 100%;
    height: 100px;
    object-fit: cover;
}
.selectedImg,
.selectedPrice {
    width: 25%;
}
.selectedPrice p {
    font-weight: 700;
}
.selectedPrice {
    text-align: center;
}
.selectedName {
    width: 50%;
}
.selectedName h2 {
    font-size: 18px;
}
.selectedPrice span {
    display: block;
    font-weight: bold;
    font-size: 12px;
    padding-left: 10px;
}
.selectedName p {
    font-size: 12px;
    color: #505050;
}

.selectedImg img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
}
.totalPrice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}
.totalPrice h3 {
    margin: 0px;
    font-weight: 700;
    color: black;
    font-size: 18px;
}
.totalPrice h4 {
    margin: 0px;
    font-size: 14px;
}
.totalPrice p {
    font-weight: 700;
    color: black;
}
.cartAddon p {
    font-size: 12px;
}
.checkoutBtn {
    border-top: 1px solid #eaeaea;
    padding: 20px 0px;

    bottom: 0;
    background-color: white;
    width: 100%;
    padding-right: 40px;
    position: absolute;
}
.cartContent {
    height: 100%;
    width: 100%;

    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 350px;
}
.cartContent::-webkit-scrollbar {
    width: 5px; /* Adjust the width as needed */
}

.cartContent::-webkit-scrollbar-thumb {
    background-color: #ea5443;
    border-radius: 50px; /* Color of the thumb */
}
.selectedTab {
    background-color: #0b9c7f;
    color: white !important;
    display: flex;
    align-items: center;
    gap: 5px;
}
.cartFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding-top: 10px;
}
.cartFooter span {
    color: #0b9c7f;
}
.cartFooter p {
    font-weight: 700;
    font-size: 14px;
}
.countDown {
    background-color: #ea5443;
    color: white;
    padding: 5px;
    border-radius: 40px;
}
.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    gap: 20px;
    border-bottom: 1px solid #eaeaea;
}
.badge span {
    color: #0b9c7f;
    font-weight: 700;
}
.badge img {
    margin-bottom: -10px;
    z-index: 99999;
}
.badge p {
    font-weight: 500;
    font-size: 12px;
    width: 60%;
}
.totalBox {
    font-size: 12px !important;
}
@media (max-width: 800px) {
    .toggleContainer {
        width: 100%;
        margin: 20px 0px;
    }
    .planToggle {
        width: 100%;
    }
    .planFooter p {
        font-size: 13px;
    }
    .addToCart {
        width: 100%;
    }
    .selectedName h2,
    .planToggle h3 {
        font-size: 16px;
    }
    .selectedBox,
    .cartSib {
        gap: 10px;
    }
    .selectedImg img {
        height: 80px;
    }
    .sibImage img {
        height: 80px;
    }
    .cartSibBtn p {
        width: 100%;
        font-size: 14px;
        justify-content: center;
    }
    .cartSibBtn {
        gap: 5px;
        flex-direction: column;
    }
    .cartFooter p {
        font-size: 12px;
    }
    /* .cartContent {
        height: 70%;
        padding-bottom: 150px;
    } */
    .selectedPrice p {
        font-size: 13px;
    }
}
