.aboutHeader {
    background-color: #258d8f;
    padding: 50px 0px;
}
.aboutHeader h1 {
    text-align: center;
    color: white;
    padding-bottom: 30px;
}
.aboutCollage {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 95%;
    margin: auto;
    column-gap: 10px;
    margin-bottom: -100px;
}
.aboutHeader img {
    width: 100%;
}
.aboutBck {
    background-color: #fff8ef;
    padding-top: 50px;
    padding-bottom: 40px;
}
.aboutContent {
    background-color: white;
    padding: 20px 50px;
    text-align: left !important;
    border-radius: 8px;
}
.aboutBck img {
    width: 100%;
}
.aboutBck p {
    padding-bottom: 20px;
}
.aboutButton {
    display: flex;
    align-items: center;
    gap: 15px;
}
.aboutButton a {
    display: flex;
    align-items: center;
    border-radius: 50px;
    gap: 10px;
    font-weight: bold;
}
.aboutButton img {
    filter: invert(100%);
    height: 10px;
    width: 10px;
    display: block;
}

.aboutButton a:nth-child(1) {
    background-color: #258d8f;
    color: white;
    padding: 10px 20px;
    transition: 0.3s;
}
.aboutButton a:nth-child(1):hover {
    background-color: #ea5443;
}
.aboutButton a:nth-child(2) {
    background-color: #ea5443;
    color: white;
    padding: 10px 20px;
    transition: 0.3s;
}
.aboutButton a:nth-child(2):hover {
    background-color: #258d8f;
}
.mobileAbout {
    display: none;
}
@media (max-width: 800px) {
    .aboutCollage {
        display: none;
    }
    .aboutHeader h1 {
        width: 90%;
        margin: auto;
    }
    .aboutConten {
        padding: 20px;
    }
    .aboutCollage {
        width: 90%;
    }
    .aboutButton {
        flex-direction: column;
    }
    .mobileAbout {
        display: block;
        margin-bottom: -100px !important;
    }
}
