.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: center; */
    padding: 0px;

    border-radius: 8px;
}

.videoSection {
    width: 65%;

    /* padding: 10px; */
}

.video {
    width: 100%;
    border-radius: 8px;
}

.textSection {
    width: 35%;
    padding: 20px;
    text-align: left;
    /* height: 100%; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding-left: 50px;
}
.leafOne {
    position: absolute;
    right: 20px;
    top: 42%;
    z-index: 9;
    width: 40px;
}
.leafTwo {
    position: absolute;
    left: 20px;

    top: 32%;
    z-index: 9;
    width: 30px;
}
.leafThree {
    position: absolute;
    right: 20px;

    top: 38%;
    z-index: 9;
    width: 30px;
}
.badge {
    /* background-color: #ffc107; */
    color: #000;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
}
.badge p {
    font-size: 24px;
    font-weight: bold;
}
.title {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 10px;
}
.newClass .title {
    font-size: 32px;
}
.newClass .leafThree {
    right: 10px !important;
    top: 34% !important;
}
.beiPos {
    position: absolute;
    /* width: ; */
    top: 0px;
    right: -80px;
    height: 100%;
}
.sprout {
    color: #ffcc00;
}

.subtitle {
    font-size: 22px;
    color: #333;
    margin-bottom: 10px;
}

.description {
    font-size: 16px;
    /* color: #666; */
    font-weight: 600;
    margin-bottom: 20px;
}

.emphasis {
    font-weight: bold;
}
.newClass .beiPos {
    right: -70px !important;
}
.button {
    /* background-color: #28a745; */
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    transition: background-color 0.3s ease;
    width: 200px;
    transition: 0.3s;
}

.button:hover {
    background-color: black !important;
}
@media (max-width: 1200px) {
    .container {
        flex-direction: column;
        text-align: center;
    }

    .beiPos {
        display: none;
    }
    .videoSection {
        width: 100%;
    }
    .textSection {
        width: 100%;
    }
}
@media (max-width: 768px) {
    .videoSection,
    .textSection {
        max-width: 100%;
        flex: 1 1 100%;
    }

    .textSection {
        padding-right: 20px;
        padding-left: 20px;
    }

    .title {
        font-size: 24px;
    }

    .subtitle {
        font-size: 18px;
    }

    .description {
        font-size: 14px;
    }
    .leafThree {
        position: absolute;
        right: 13px;
        top: 23%;
        z-index: 9;
        width: 30px;
    }
    .newClass .leafThree {
        right: 10px !important;
        top: 30% !important;
    }
    .newClass .title {
        font-size: 28px;
    }
    .leafOne {
        top: 10%;
    }
    .leafTwo {
        display: none;
    }
}
