.rificBanner {
    background-image: url('../../../public/image/dde.webp');
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    background-color: #ea5443;
    border-radius: 20px;
    margin-top: 20px;
    padding: 50px;
}
.bannerBtn {
    background: #fbff4b;
    border-radius: 50px;
    color: black !important;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 600;
    /* margin: 0 auto; */
    padding: 10px 20px !important;
    display: flex;
    gap: 10px;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
}
.bannerBtn img {
    height: 20px;
    width: 20px;
}
.click {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: 20px;
}
.click img {
    width: 12px;
    height: 12px;
}
.rificContent {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    height: 100%;
}
.rificContent a {
    width: fit-content;
}
.rificContent h2 {
    font-size: 32px;
    color: white;
}
.rificContent p {
    color: white;
    padding: 10px 0px;
}
.worksDis {
    background-color: #fff7ef;
    border-radius: 20px;
    display: flex;

    padding: 40px;
    gap: 20px;
}
.worksDis h3 {
    font-size: 26px;
    margin: 0px;
    padding: 10px 0px;
}
.worksDis p {
    font-weight: 500;
    font-size: 16px;
}
.rificWorks h2 {
    color: #ea5443;
    font-size: 38px;
    padding-top: 50px;
    padding-bottom: 20px;
}
.worksDis div {
    width: 30%;
    padding: 0px 20px;
}
.vendorBox div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.vendorCheck {
    border-bottom: 1px solid #000000;
    padding: 15px 20px;
}
.vendorCheck:nth-last-child(1) {
    border-bottom: unset;
}
.vendorBox span {
    color: #a8a8a8;
    font-size: 14px;
}
.vendorBox {
    border: 1px solid #000000;
    border-radius: 20px;
    margin-top: 20px;
}
.vendorBox p {
    font-weight: 600;
    font-size: 18px;
}
.vendorCheck div {
    display: flex;
    align-items: center;
    gap: 10px;
}
.vendorCheck img {
    height: 20px;
}
.vendor h2 {
    font-size: 38px;
}
.vendor h2 span {
    color: #ea5443;
    display: block;
}
.vendor {
    padding-top: 50px;
}
.placing {
    display: flex;
    padding: 100px 0px;
    align-items: center;
    justify-content: space-between;
}
.placingContent,
.placingPlans {
    width: 45%;
}
.mainImage img {
    height: 160px;
}
.mainImage {
    padding: 10px 0px;
}
.placingHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ea5443;
    padding: 2px 20px;
    border-radius: 8px;
    width: 100%;
}
.placingBox {
    border: 2px solid #ea5443;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-bottom: 20px; */
    cursor: pointer;
}
.placingZoo {
    border: 2px solid var(--sub-main);
}
.placingZoo .placingHeading {
    background-color: var(--sub-main);
}
.placingHeading div {
    display: flex;
    align-items: center;
    gap: 5px;
}
.placingHeading img {
    height: 30px;
}
.placingHeading p {
    color: white;
    font-weight: 500;
}
.placingContent h3 {
    font-size: 38px;
    color: #ea5443;
    margin: 0px;
    padding-bottom: 30px;
}

.placingContent p {
    padding-bottom: 30px;
    font-weight: 500;
    font-size: 18px;
}
.placingContent a {
    color: #ea5443;
}
.placingContent span {
    color: #268d8f;
    font-size: 18px;
}
.placingBottom p {
    font-weight: 600;
}
.placingPlans {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.placingBottom {
    width: 100%;
}
.placingBottom p {
    text-align: center;
}
.placingBottom div {
    display: flex;
    width: 75%;
    margin: auto;
}
.placingBottom ul {
    list-style: disc;
    padding-left: 20px;
}
.placingBottom li {
    font-weight: 500;
}
.placingBottom {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.placingBottom.show {
    max-height: 500px; /* Adjust this value as per your content */
}
.click {
    font-weight: 600;
}
@media (max-width: 800px) {
    .worksDis div {
        width: 100%;
        background-color: #fff7ef;
        padding: 20px;
        border-radius: 20px;
    }

    .worksDis {
        flex-direction: column;
        background-color: unset;
        padding: 0px;
    }
    .vendorCheck {
        flex-direction: column;
    }
    .vendorBox div {
        align-items: flex-start;
    }
    .vendorBox span {
        padding-left: 31px;
    }
    .vendorCheck div {
        align-items: center;
    }
    .vendor h2 {
        text-align: center;
    }
    .placing {
        flex-direction: column;
        gap: 20px;
    }
    .placingContent,
    .placingPlans {
        width: 100%;
    }
    .placingBottom div {
        width: 90%;
    }
    .vendorBox p {
        width: 60%;
    }
    .vendorCheck div {
        justify-content: flex-start;
    }
    .placingContent h3 {
        text-align: center;
    }
    .rificBanner {
        background-image: url('../../../public/image/m2.png');
        height: 570px;
        object-fit: cover;
        width: 100%;
        padding: 20px;
        border-radius: 30px;
    }
    .rificContent {
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        width: 100%;
    }
    .rificContent h2 {
        font-size: 25px;
    }
}
