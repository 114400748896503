.kidBck {
    background: #c3f1f2;
    text-align: center;
    padding-top: 100px;
    background-image: url('../../../public/image/fall.png');
}
.kidBck p {
    border-radius: 75px;
    background: #fbff4b;
    width: 425px;
    margin: auto;
    padding: 5px;
    font-weight: 700;
}
.kidSwiper img {
    /* width: 204px;
    height: 226px; */
    width: 100%;
}
.bamboBck {
    background-color: #27964c;
    background-image: url('../../../public/image/bambo.png') !important;
    background-repeat: no-repeat;
    background-size: cover;
}
.snowbck {
    background-color: #27964c;
    background-image: url('../../../public/image/sn.png') !important;
    background-repeat: no-repeat;
    background-size: cover;
}
@media (max-width: 1024px) {
}
@media (max-width: 800px) {
    .kidBck p {
        width: 100%;
    }
    .mobBox {
        /* margin-left: 20px; */
    }
    .kidBck {
        padding-top: 150px;
    }
}
