.hallowenBck {
    background-color: #042b09;
    background-image: url('../../../public/image/bck.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 100px 0px;
}
.hallDis {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    column-gap: 10px;
    width: 900px;
    margin: auto;
    padding-bottom: 20px;
}
.hallDisTwo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    column-gap: 10px;
    width: 600px;
    margin: auto;
}
.price {
    color: #0b9c7f;
    font-weight: bold;
    padding-bottom: 10px;
    display: block;
}
.clubIcon {
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    padding: 8px 20px !important;
    color: white;
    margin: auto;
    border-radius: 12px;
    margin-bottom: 10px;
}
.clubIcon p {
    padding: 0px !important;
}
.tabs button {
    display: flex;
    text-align: left;
    gap: 5px;
    align-items: center;
}
.tabs img {
    width: 40px;
    height: 40px;
}
.tabs {
    padding-bottom: 20px;
}
.tabs p {
    font-weight: 600;
}
.zooTab {
    border-radius: 50px 0px 0px 50px;
    background-color: #d6d6d6;
    color: #777777;
}
.zooTab.activeTab {
    background-color: var(--sub-main);
    color: white;
}
.cubTab {
    border-radius: 0px 50px 50px 0px;
    background-color: #d6d6d6;
    color: #777777;
}
.cubTab.activeTab {
    background-color: var(--main);
    color: white;
}
.bundledDis {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 600px;
    background-color: #ff7628;
    margin: auto;
    border-radius: 40px;
    padding: 20px;
    position: relative;
}
.bundleCub {
    background-color: var(--main);
}
.bundleCub h3 {
    color: #cee21d;
}

.bundledImage {
    width: 55%;
}
.bundledContent {
    width: 45%;
}
.bundledImage img {
    width: 100%;
}
.bundleCub .bundledContent,
.bundleCub .bundledImage {
    width: 50%;
}
.bundledContent {
    color: white;
    padding: 0px 20px;
    text-align: center;
}
.bundledContent h1 {
    font-size: 62px;
    padding: 5px 0px;
}
.bundledContent span {
    text-decoration: line-through;
}
.bundleDiscount {
    text-decoration: none;
    background-color: white;
    color: #0b9c7f;
    font-weight: 600;
    padding: 5px 7px;
    position: absolute;
    right: 25px;
    border-radius: 12px;
}
.bundleCub .bundleDiscount {
    background-color: #cee21d;
}
.bundledContent h3 {
    margin: 0px;
}
.bundledContent a {
    background-color: #0b9c7f;
    color: white;
    border: 1px solid #0b9c7f;
    text-decoration: none;
    padding: 7px;
    width: 80%;
    margin: auto;
    border-radius: 50px;
    display: block;
    font-size: 16px;
    transition: 0.3s;
    /* margin-bottom: 10px; */
}
.bundledContent a:hover {
    background-color: #042b09;
    border: 1px solid #042b09;
}
.tabs {
    display: flex;
    width: 400px;
    margin: auto;
    align-items: center;
    justify-content: center;
}
.hallDis .clubIcon {
    background-color: var(--sub-main);
}
.hallDisTwo .clubIcon {
    background-color: var(--main);
}
.clubIcon img {
    width: 20px;
}
.boxCard {
    background-color: white;
    border-radius: 12px;
    padding: 20px 10px;
    text-align: center;
    justify-content: center;
}
.hallDis a {
    background-color: #0b9c7f;
    color: white;
    border: 1px solid #0b9c7f;
    text-decoration: none;
    padding: 7px;
    width: 80%;
    margin: auto;
    border-radius: 50px;
    display: block;
    font-size: 16px;
    transition: 0.3s;
    margin-bottom: 10px;
}
.openPopUp {
    border: 1px solid #0b9c7f;
    color: #0b9c7f;
    text-decoration: none;
    padding: 7px !important;
    width: 80%;

    margin: auto;
    border-radius: 50px;
    display: block;
    font-size: 16px;
    transition: 0.3s;
    cursor: pointer;
}

.openPopUp:hover {
    border: 1px solid #042b09;
    color: #042b09;
}
.digitalImage {
    width: 100%;
    margin: auto;
}
.digitalImage img {
    width: 100%;
}
.hallDis a:hover {
    background-color: #042b09;
}
.spookTitle {
    width: 50%;
    margin: auto;
    padding-bottom: 20px;
}
.spookTitle img {
    width: 100%;
}
.hallDis h2 {
    font-size: 24px;
    color: #dd5c43;
}
.hallDis p {
    padding: 10px 0px;
    font-weight: 600;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    z-index: 9999999;
}

.modalContent {
    background: white;
    padding: 20px;
    padding-bottom: 60px;
    max-width: 500px;
    width: 100%;
    text-align: center;
    position: relative;
    border-radius: 10px;
}

.closeModal {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px !important;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    background-color: #dd5c43;
}
.modalBigText {
    height: 200px;
    overflow-y: scroll;
    text-align: left;
    font-size: 14px;
    padding-top: 10px;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #dd5c43 #f1f1f1; /* Thumb and track color for Firefox */
}

/* For WebKit browsers (Chrome, Safari, Edge) */
.modalBigText::-webkit-scrollbar {
    width: 6px; /* Adjust scrollbar width */
}

.modalBigText::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background color of the scrollbar track */
}

.modalBigText::-webkit-scrollbar-thumb {
    background-color: #dd5c43; /* Scrollbar thumb color */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.modalBigText::-webkit-scrollbar-thumb:hover {
    background-color: #c14e39; /* Darker thumb color on hover */
}

.modalImage img {
    width: 65%;
    height: auto;
}

.modalAddToCart {
    display: inline-block;
    margin-top: 10px;
    padding: 15px 20px;
    background-color: #0b9c7f;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 15px 15px 5px 5px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    transition: 0.3s;
}
.modalAddToCart:hover {
    background-color: #042b09;
}
.modal h2 {
    color: #dd5c43;
    font-size: 32px;
}
.modalSub {
    font-weight: bold;
}
.clicking {
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    padding-top: 10px;
    color: #514f4e !important;
    border-top: 1px solid #d6d6d6;
}
.modalPrice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding-bottom: 20px;
}
.modalPrice span {
    font-weight: bold;
    color: #0b9c7f;
}
@media (max-width: 1200px) {
    .hallDis {
        grid-template-columns: repeat(1, 1fr);
        width: 80%;
        row-gap: 10px;
    }
}

@media (max-width: 768px) {
    .hallDis {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        row-gap: 10px;
    }
    .hallowenBck {
        background-image: url('../../../public/image/mob.png');
        padding: 40px 0px;
    }
    .spookTitle {
        width: 100%;
    }
    .modalContent {
        max-width: 92%;
    }
    .tabs {
        width: 100%;
    }
    .tabs img {
        width: 30px;
    }
    .bundledDis {
        width: 100%;
        flex-direction: column;
    }
    .bundledContent,
    .bundledImage,
    .bundleCub .bundledContent,
    .bundleCub .bundledImage {
        width: 100%;
    }
    .bundleDiscount {
        bottom: 100px;
        right: 45px;
    }
}
