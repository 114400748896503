.promotionBck {
    background-color: #ff7628;
    width: 100%;
    height: 400px;
    margin-bottom: -300px;
}
.promotionDis {
    text-align: center;
}
.promotionDis {
    display: flex;
}
.promotionheading {
    color: white;
    text-align: center;
}
.promotionContent {
    width: 55%;
}
.promotionBanner {
    width: 45%;
}
.promotionheading {
    padding-bottom: 50px;
    color: white;
    font-size: 56px;
}

.promotionContent {
    background-color: white;
    border-radius: 50px;
    padding: 50px;
}
.promotionBanner img {
    width: 100%;
}
.promotionContent h3 {
    color: #258d8f;
    font-weight: 300;
    font-size: 24px;
}
.promotionContent h2 {
    color: #ff7628;
    padding-bottom: 20px;
}
.promotionContent p {
    text-align: left;
    padding-bottom: 20px;
    font-weight: 600;
}
.promotionContent a {
    color: #ff7628;
    text-decoration: underline;
}
.contentBck {
    background-color: #fff7ef;
}
@media (max-width: 800px) {
    .promotionDis {
        flex-direction: column;
    }
    .promotionBanner,
    .promotionContent {
        width: 100%;
    }
    .promotionContent {
        padding: 20px;
    }
    .promotionheading {
        font-size: 32px;
    }
}
