.helps {
    text-align: center;
    /* background: #fff3f1; */
    margin-bottom: -100px;
}
.cubTitle {
    color: #ffce00;
}
.helps h1 {
    font-size: 30px;
    color: black;
}
.zooTitle {
    /* padding-top: 150px; */
    margin-top: 150px;
    color: black !important;
}

.helpsBox {
    display: flex;
    width: 90%;
    margin: auto;
    gap: 15px;
}
.reverse {
    flex-direction: row-reverse;
}
.helpsBox div {
    width: 50%;
}
.helpsDis img {
    width: 100%;
    border-radius: 20px;
}
.helpsBox:nth-child(1) {
    padding-bottom: 15px;
}
.helpsContent {
    border-radius: 35px;
    border: 1px solid #efefef;

    background: #fff;
    padding: 30px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.helpsContent h2 {
    font-size: 30px;
    padding-bottom: 20px;
}
.helpsContent p {
    font-size: 18px;
    font-weight: 700;
}
@media (max-width: 1024px) {
}
@media (max-width: 800px) {
    .helpsBox {
        flex-direction: column;
        width: 100%;
    }
    .helpsBox div {
        width: 100%;
    }
    .helpsBox {
        gap: 0px;
    }
    .helpsContent {
        border-radius: 20px 20px 0px 0px;
    }
    .helpsDis img {
        width: 100%;
        border-radius: 0px 0px 20px 20px;
    }
}
