.product {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.image {
    width: 100px;
    height: 100px;
}

.addButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.addButton:hover {
    background-color: #0056b3;
}
