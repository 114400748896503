.programBck {
    background-color: #ff7628;
    text-align: center;
    padding: 50px 0px;
}
.programHeading {
    color: white;
    padding-bottom: 20px;
}
.programHeading p {
    width: 50%;
    margin: auto;
}
.programBck img {
    width: 100%;
}
.programSwiper {
    margin-bottom: -100px;
}
.programContent {
    padding-top: 100px;
    background-color: #fff8ef;
    padding-bottom: 50px;
}
.programBox {
    text-align: center;
}
.programBox h2 {
    font-size: 38px;
    color: #ea5443;
}
.programBox li {
    font-size: 16px;
    padding-bottom: 10px;
    font-weight: 600;
}
.programBox a {
    display: flex;
    background-color: #ea5443;
    color: white;
    width: fit-content;
    padding: 10px 20px;
    gap: 5px;
    border-radius: 50px;
    align-items: center;
    margin: auto;
}
.programBox img {
    filter: invert(100%);
}
@media (max-width: 800px) {
    .programHeading p {
        width: 100%;
    }
}
