.banner {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
}
.banner div:nth-child(1) {
    width: 45%;
    background-color: #de2c2c;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 50px;
    background-image: url('../../../public/image/snoww.png');
    position: relative;
}
.wave {
    background-image: url('../../../public/image/wav.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.st1 {
    position: absolute;
    left: 100px;
    top: 70px;
}
.tabsBck {
    background-image: url('../../../public/image/line.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0px;
    position: relative;
}
.x1 {
    position: absolute;
    left: -50px;
    top: 150px;
    height: 80px;
}
.x11 {
    position: absolute;
    left: 120px;
    top: 250px;
    height: 100px;
}
.x2 {
    position: absolute;
    right: -50px;
    top: 100px;
    height: 80px;
}
.x22 {
    position: absolute;
    right: 50px;
    top: 250px;
    height: 60px;
}
.x3 {
    position: absolute;
    left: -50px;
    top: 60%;
    height: 80px;
}
.x33 {
    position: absolute;
    left: 120px;
    bottom: 250px;
    height: 100px;
}
.x4 {
    position: absolute;
    right: -50px;
    bottom: 150px;
    height: 80px;
}
.x44 {
    position: absolute;
    right: 100px;
    bottom: 250px;
    height: 60px;
}

.helpsColor {
    background-color: #de2c2c;
    background-image: url('../../../public/image/cubLine.png');
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
}

.st2 {
    position: absolute;
    right: 100px;
    bottom: 50px;
}
.clubPos {
    position: absolute;

    right: 0px;
    bottom: 0px;
    height: 220px !important;
}
.banner div:nth-child(2) {
    display: flex;
    align-items: center;
    width: 55%;
}
.banner h1 {
    font-size: 38px;
    color: white;
    position: relative;

    z-index: 999;
}
.banner p {
    color: white;
    font-size: 15px;
    position: relative;

    z-index: 999;
    padding: 20px 0px;
}
.banner img {
    height: 400px;
    border-radius: 20px;
}
.buttonDis {
    display: flex;
    align-items: center;
}
.buttonDis a {
    margin: 5px;
}
.cubIcon {
    height: 50px !important;
    width: 50px !important;
}
.cubTitle {
    color: #ffce00;
}
@media (max-width: 1024px) {
    .banner {
        flex-direction: column;
    }
    .banner div {
        width: 100% !important;
    }
    .banner div:nth-child(2) {
        justify-content: center;
    }
    .banner img {
        width: 100%;
        height: unset;
    }
    .clubPos {
        height: auto !important;
        width: 150px !important;
    }
}
@media (max-width: 800px) {
    .banner div:nth-child(2) {
        justify-content: center;
        flex-direction: column;
    }
    .banner div:nth-child(1) {
        padding: 20px;
    }
    .banner img {
        width: 100%;
        height: auto;
    }
    .learnDis {
        grid-template-columns: repeat(1, 1fr);
    }
    .banner div:nth-child(1) {
        border-radius: 20px 20px 0px 0px;
    }
    .banner img {
        border-radius: 0px 0px 20px 20px;
    }
    .buttonDis img {
        border-radius: 50px;
    }

    .banner div:nth-child(1) {
        padding-bottom: 220px;
        background-repeat: no-repeat;
    }
    .x2 {
        height: 40px;
        right: 0px;
        top: 140px;
    }
    .x1 {
        left: 0;
        top: 150px;
        height: 33px;
    }
    .x4 {
        right: 0px;
        bottom: 50px;
        height: 55px;
    }
    .x3 {
        display: none;
    }
    .x22 {
        right: 50px;
        top: 11px;
        height: 30px;
    }
    .x11 {
        left: 52px;
        top: 121px;
        height: 36px;
    }
    .x33,
    .x44 {
        display: none;
    }
    .tabsBck {
        background-repeat: repeat;
        background-size: contain;
    }
}
