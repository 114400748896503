.tabsDis {
    display: flex;
    align-items: center;
    width: 562px;
    margin: auto;
    text-align: center;
    justify-content: center;
    /* background: #ffa59b; */
    border-radius: 70px;
    position: relative;
}
.cubColor {
    background: #ffa59b;
}
.zooColor {
    background: #ffc3a0;
}

.tabsDis p {
    padding: 10px;
    border-radius: 50px;
    color: white;
    cursor: pointer;
    position: relative;
    z-index: 9999;
}
.tabsDis p:nth-child(1) {
    width: 60%;
}
.tabsDis p:nth-child(2) {
    width: 40%;
}

.tabsDis span {
    /* background-color: #ea5443; */
    position: absolute;

    height: 100%;
    border-radius: 50px;
}
.cubColor span {
    background-color: #ea5443;
}
.zooColor span {
    background-color: #ff7628;
}
.activeSpan {
    left: 0;
    width: 60%;
    transition: all 0.3s ease-in;
}
.activeSpanTwo {
    left: calc(100% - 40%);
    width: 40%;
    transition: all 0.3s ease-in;
}
.showTab {
    width: 90%;
    margin: auto;
}
/*Edventure*/
.edventureBox {
    display: flex;
    align-items: center;
}
.edventure img {
    height: 160px;
}
.edventure h3 {
    margin: 0px;
}
.edventureBox div {
    padding-left: 20px;
}
.edventure {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    column-gap: 20px;
    row-gap: 20px;
    justify-content: center;
}
.guide {
    padding: 50px 0px;
}
.bookDis {
    display: flex;
    align-items: center;
    gap: 20px;
}
.bookDis img {
    height: 130px;
}
.bookDis h3 {
    margin: 0px;
    font-size: 24px;
}
.bookDis p {
    font-weight: 400;
}
.guideDis {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    column-gap: 20px;
    row-gap: 20px;
    justify-content: center;
    padding-top: 50px;
}
.guideBox {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}
.guideBox img {
    height: 35px;
}
@media (max-width: 1024px) {
}
@media (max-width: 800px) {
    .tabsDis {
        width: 100%;
    }
    .edventure,
    .guideDis {
        grid-template-columns: repeat(1, 1fr);
    }
    .edventure img {
        height: 120px;
    }
    .bookDis {
        align-items: flex-start;

        flex-direction: column;
    }
    .edventureBox {
        flex-direction: column;
        align-items: flex-start;
    }
    .edventure {
        gap: 50px;
    }
    .edventureBox div {
        padding-left: 0px;
    }
}
