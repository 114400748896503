/* ShippingInfo.module.css */

.container {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.card {
    width: 220px;
    padding: 20px;
    /* border-radius: 10px; */
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iconContainer {
    /* background-color: white; */
    border-radius: 50%;
    /* padding: 10px; */
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconContainer img {
    width: 100px;
    height: 100px;
}
.changeColor p {
    color: black;
}
.text {
    font-size: 16px;
    font-weight: 600;
}
@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }
    .card {
        width: 95%;
        margin: auto;
    }
}
