.curiosityBox {
    /* border-radius: 27.7px; */
    /* border: 0.791px solid #efefef; */
    position: relative;
    /* background: #fff; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    /* background-image: url('../../../public/image/cor.png'); */
    border: 3px solid var(--main);
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
}
.curiosContent p {
    font-size: 22px;
    font-weight: 900;
}
.curiosContent img {
    height: 70px;
}
.clubSelect {
    display: flex;
    position: absolute;
    top: 10px;
    align-items: center;
    gap: 5px;
}
.clubSelect p {
    font-size: 14px;
}
.clubSelect img {
    width: 20px;
}
.curiosityDis {
    display: grid;
    column-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
}
.curiosityDis span {
    color: var(--sub-main);
    font-size: 18px;
    font-weight: 700;
}
.curiosContent {
    width: 30%;
}
.mainboxImage {
    width: 60%;
}
.curiosity {
    background: #fefff0;

    padding-bottom: 50px;
}
.curiosity h1 {
    color: var(--sub-main);
    font-weight: 800;
}
.true {
    background-image: url('../../../public/image/bc.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.curiosity p {
    font-weight: 600;
}
.mainboxImage img {
    width: 100%;
}
.mobile {
    display: none;
}
.desktop {
    display: grid;
}
/* .Zoologist {
    background-image: url('../../../public/image/zoc.png');
}
.land {
    background-image: url('../../../public/image/green.png');
} */
/* .Cub {
    border: 3px solid var(--main) !important;
} */
.Cub span {
    color: var(--main) !important;
}
@media (max-width: 1024px) {
}
@media (max-width: 800px) {
    .curiosityDis {
        grid-template-columns: repeat(1, 1fr);
    }
    .mobile {
        display: block;
    }
    .desktop {
        display: none;
    }
    .curiosContent {
        width: 100%;
        text-align: center;
    }
    .mainboxImage {
        width: 100%;
    }
    .curiosContent p {
        font-size: 18px;
    }
    .curiosity {
        padding-bottom: 50px;
    }
    /* .curiosityBox {
        background-image: url('../../../public/image/icomob.png');
        background-size: contain;
    }
    .Zoologist {
        background-image: url('../../../public/image/icomob3.png') !important;
        background-size: contain;
    }
    .land {
        background-image: url('../../../public/image/icomob4.png') !important;
        background-size: contain;
    } */
    .curiosityBox {
        margin-left: 20px;
        flex-direction: column;
        gap: 20px;
    }
    .curiosity {
        padding-bottom: 0px !important;
    }
    .mainboxImage {
        width: 65%;
        margin: auto;
    }
    .curiosityBox {
        justify-content: center;
        gap: 10px;
    }
    /* .clubSelect {
        display: none;
    } */
    .curiosityDis {
        padding-top: 0px !important;
    }
    .clubSelect {
        left: 20px;
    }
}
