.filter select {
    border: none;
    color: black;
    padding: 5px;
    background-color: #ebedec;
    border-radius: 50px;
    cursor: pointer;
}
.filter option {
    background-color: #ebedec;
    border: unset !important;
    border-radius: 0px 0px 20px 20px;
}
.filterDis {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 50px;
}
.filterBoxDis {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    column-gap: 20px;
    row-gap: 20px;
}
.filterBoxSingle {
    display: flex;
    align-items: center;
}
.filterBoxSingle .priceDis p {
    padding-bottom: 10px;
}
.filterBoxSingle .priceDis h3 {
    font-size: 24px;
}
.filterBoxSingle img {
    width: 100%;
    width: 250px;
}
.filterBoxSingle .priceDis {
    flex-direction: column !important;
    justify-content: center;
}
.fanHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}
.fanHeader h2 {
    color: #ea5443;
    font-size: 32px;
}
.fanHeader a {
    color: #0b9c7f;
    cursor: pointer;
    font-weight: 500;
}
.searchProduct {
    background-color: #f3fffc;
    padding: 50px 0px;
}
.dropdown {
    background-color: white;
    padding: 15px;
}
.dropdown div {
    padding-bottom: 10px;
}
.searchDis {
    display: flex;
    /* align-items: center; */
    gap: 10px;
}
.searchDis div {
    background-color: #004f44;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.dropdown a {
    font-size: 22px;
    /* color: #0b9c7f; */
}
.searchProduct input {
    border: 1px solid #004f44;
    width: 100%;
    padding: 15px;
}
.filterBoxDis img {
    width: 250px;
    height: 300px;
    object-fit: contain;
    margin: auto;
}
.filterBox {
    border: 1px solid #f7f5f7;
    padding: 20px;
    text-align: left;
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.latestProductsSection h2 {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    gap: 10px;
}
.latestProductsSection h2 {
    font-size: 32px;
}
.priceDis {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
}
.priceDis h3 {
    margin: 0px;
    font-size: 16px;
    color: #6b7489;
    font-weight: 500;
}
.priceDis p {
    font-weight: 700;
    color: #344054;
}
.boxView {
    text-align: center;
    background-color: #0b9c7f;
    border: 1px solid #2e7084;
    padding: 7px;
    border-radius: 50px;
    color: white;
    width: 100%;
    cursor: pointer;
}
.productStock {
    background-color: #fff0f0;
    color: #d9493a;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 700;
    /* position: absolute; */
    position: absolute;
    right: 0px;
    width: fit-content;
}
.productStockTwo {
    background-color: #fff0f0;
    color: #d9493a;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 700;
    /* position: absolute; */
}
.productsDis {
    display: flex;
    align-items: center;
    gap: 10px;
}
.ZooColor {
    color: var(--sub-main);
}
.productAge {
    background-color: var(--sub-main);
    color: white;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 10px;
    font-weight: 700;
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.productAge img {
    height: 15px;
    width: 15px;
}
.cubAge {
    background-color: var(--main);
}
.shopBanner {
    position: relative;
}
.shopBanner img {
    width: 100%;
    margin: 50px 0px;
}
.shopBanner h2 {
    position: absolute;
    padding-left: 50px;
    top: 0;
    bottom: 0;
    display: flex;
    font-size: 32px;
    margin: auto;
    width: 500px;
    flex-direction: column;
    justify-content: center;
}
.shopStem img {
    width: 100%;
    border-radius: 20px;
}
.shopStem {
    position: relative;
}
.shopStem div {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 350px;
    margin: auto;
    text-align: center;
    align-items: center;
}
.shopStem {
    position: relative;
}
.shopStem a {
    background-color: var(--sub-main);
    color: white;
    width: 200px;
    display: block;
    text-align: center;
    padding: 10px;
    border-radius: 50px;
    position: absolute;
    /* top: -150px; */
    bottom: 650%;
    left: 24%;
    font-weight: 700;
}
.shopStem h2 {
    font-size: 32px;
    color: #0b9c7f;
    font-weight: bold;
}
.shopStem p {
    padding: 10px 0px;
    font-weight: 700;
}

.shopStem a img {
    width: 10px !important;
}
.cubTitle {
    color: var(--main);
}
.productDetails {
    display: flex;
    /* align-items: center; */
    padding-top: 50px;
    gap: 100px;
}
.productCarousel {
    /* border: 1px solid #e4e4e4; */
    border-radius: 10px;
    padding: 20px;
}
.productCarousel,
.productContent {
    width: 50%;
}
.productContent {
    position: relative;
}
.productCarousel img {
    width: 80%;
    margin: auto;
    display: flex;
}
.productType {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 10px;
}
.smallProductsDis {
    /* display: flex; */
    width: 100% !important;
    margin: auto;
    /* flex-direction: column !important; */
}
.smallProducts {
    width: 100% !important;
}
.productCarouselDis {
    display: flex;
    align-items: center;
    gap: 10px;
}
.productType h1 {
    padding-bottom: 5px;
}
.productType div p {
    color: #b9bbbf;
    font-size: 14px;
}
.productContent h2 {
    padding-top: 20px;
}
.stock {
    font-size: 16px;
    color: #d9493a;
    background-color: #fff0f0;
    border-radius: 15px;
    font-weight: 500;
    padding: 8px 15px;
}
.cart {
    margin-top: 20px;
    background-color: var(--sub-main);
    width: 250px;
    display: flex;
    padding: 15px;
    border-radius: 50px;
    justify-content: center;
    text-align: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    color: white;
}
.zooTitle {
    color: var(--sub-main);
}
/* .productDescription {
    padding-bottom: 50px;
} */
.productDescription p {
    color: black;
    font-size: 14px;
    line-height: 1.5rem;
}
.productInfo {
    display: flex;
    flex-direction: column;
    border: 1px solid #e4e4e4;
    border-radius: 12px;
    /* padding: 8px; */
}
.delivery,
.refund {
    display: flex;
    align-items: flex-start;
    padding: 15px;
    gap: 10px;
    width: 95%;
    margin: auto;
}
.delivery p,
.refund p {
    font-weight: 700;
}
.delivery {
    border-bottom: 1px solid #e4e4e4;
}
.delivery a,
.refund a {
    color: #726c6c;
    font-size: 12px;
}
.delivery a {
    text-decoration: underline;
}
.productClub {
    display: flex;
    align-items: center;
    gap: 5px;
}
.productClub img {
    height: 20px;
}
.productClub {
    width: fit-content;
    padding: 5px 15px;
    border-radius: 12px;
    color: white;
}
.productClub p {
    font-size: 12px;
    font-weight: 500;
}
.shopLetter {
    padding-top: 100px;
}
.shopBanner img {
    width: 100%;
    border-radius: 20px;
}

.typeDis {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    column-gap: 10px;
    row-gap: 10px;
}
.sectionBox {
    cursor: pointer;
}
.typeDis img {
    width: 100%;
    height: 160px;
    object-position: top;
    object-fit: cover;
    display: block;
}
.type {
    padding: 50px 0px;
}
.sectionBox div {
    /* background-color: var(--main); */
    padding: 10px;
    text-align: center;
}
.pred {
    background-color: #258d8f;
}
.afr {
    background-color: var(--main);
}
.rain {
    background-color: #ff8c4b;
}
.fly {
    background-color: #ec6e55;
}
.ocean {
    background-color: #258d8f;
}
.sectionBox h3 {
    margin: 0px;
    font-size: 24px;
    color: white;
}
.sectionBox p {
    font-size: 12px;
    color: white;
}
.sectionCont div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
}
.sectionCont img {
    height: 10px;
    width: 10px;
    filter: invert(100%);
}

.sectionBox h2 {
    color: var(--main);
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    padding: 20px 0px;
}
.wildLife img {
    width: 100%;
}
.wildLife {
    position: relative;
}
.wildLife div {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 100px;
    bottom: 0;
    justify-content: center;
    width: 300px;
}
.wildLife p {
    padding: 10px 0px;
}
.wildLife a img {
    width: 10px !important;
}
.wildLife h2 {
    font-size: 32px;
    color: var(--main);
}
.wildLife a {
    width: fit-content;
}
.shopMob {
    display: none;
}
.filterHeading {
    padding-bottom: 20px;
    font-size: 32px;
}
.showMessage {
    width: 100%;
    margin: 0px;
}

.digitalUnitStidy {
    padding-top: 50px;
}
.discountPrice {
    text-decoration: line-through;
    color: red;
}
.discountDis {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 20px;
    width: 100%;
}
@media (max-width: 1200px) {
    .filterBoxDis {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 800px) {
    .filterBoxDis {
        grid-template-columns: repeat(2, 1fr);
    }
    .filterBoxDis img {
        width: 120px;
        height: 120px;
    }
    .filterBox {
        padding: 15px;
    }
    .productAge img {
        width: 15px;
        height: 15px;
    }
    .shopBanner h2 {
        width: 100%;
    }
    .shopDes {
        display: none;
    }
    .shopMob {
        display: block;
    }
    .typeDis {
        grid-template-columns: repeat(1, 1fr);
    }
    .wildLife div {
        justify-content: flex-start;
        left: 20px;
        top: 20px;
    }
    .shopStem div {
        display: none;
    }
    .shopBanner h2 {
        justify-content: flex-start;
        font-size: 24px;
        padding-left: 0px;

        padding: 20px;
    }
    .productDetails {
        flex-direction: column;
        gap: 20px;
    }
    .productCarousel,
    .productContent {
        width: 100%;
    }
    .productType {
        flex-direction: column;
    }
    .productType h1 {
        font-size: 32px;
    }
    .filterDis div:nth-child(1) {
        width: 40%;
    }
    .filterDis div:nth-child(2) {
        width: 20%;
    }
    .filterDis div:nth-child(3) {
        width: 40%;
    }
    .filterDis select {
        width: 100%;
    }
    .productCarouselDis {
        flex-direction: column-reverse;
    }
    .productCarousel {
        padding: 0px;
    }
    .filterBoxSingle {
        flex-direction: column;
    }
    .smallProductsDis {
        /* display: flex; */
        width: 20% !important;
        margin: auto;
        /* flex-direction: column !important; */
    }
    .productsDis {
        flex-direction: column;
        align-items: flex-start;
    }
}
