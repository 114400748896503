.banner {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
}
.banner div:nth-child(1) {
    width: 45%;
    background-color: #ff7628;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 50px;
}

.banner div:nth-child(2) {
    display: flex;
    align-items: center;
    width: 55%;
}
.banner h1 {
    font-size: 38px;
    color: white;
}
.banner p {
    color: white;
    font-size: 15px;
    padding: 20px 0px;
}
.banner img {
    height: 400px;
    border-radius: 20px;
}
.buttonDis {
    display: flex;
    align-items: center;
}
.buttonDis a {
    margin: 5px;
}
.cubIcon {
    height: 50px !important;
    width: 50px !important;
}
.zooTitle {
    color: var(--sub-main);
}
@media (max-width: 1024px) {
    .banner {
        flex-direction: column;
    }
    .banner div {
        width: 100% !important;
    }
    .banner div:nth-child(2) {
        justify-content: center;
    }
}
@media (max-width: 800px) {
    .banner div:nth-child(2) {
        justify-content: center;
        flex-direction: column;
    }
    .banner div:nth-child(1) {
        padding: 20px;
    }
    .banner img {
        width: 100%;
        height: auto;
    }
    .banner div:nth-child(1) {
        border-radius: 20px 20px 0px 0px;
    }
    .banner img {
        border-radius: 0px 0px 20px 20px;
    }
    .buttonDis img {
        border-radius: 50px;
    }
}
