.container {
    display: flex;
    padding-top: 100px;
}

.productList {
    flex: 1;
}

.addToCart {
    flex: 1;
    padding: 20px;
}

.selectedImage {
    width: 200px;
    height: 200px;
}
.addToCart {
    position: fixed;
    top: 50%;
    right: 0;
    height: 100vh;
    width: 500px;
    transform: translateY(-50%);
    background-color: white;
    padding: 20px;
    padding-top: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-out; /* Add transition for smooth movement */

    /* Additional styles */
    z-index: 99999999; /* Ensure it's on top of other content */
}

.addToCart.hidden {
    transform: translateX(100%); /* Move it out of the screen when hidden */
}
.addToCartButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.addToCartButton:hover {
    background-color: #0056b3;
}

.shoppingCart {
    max-width: 300px;
    padding: 20px;
    background-color: #f8f9fa;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateX(-100%);
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1000;
}

.shoppingCart.open {
    transform: translateX(0);
}

.shoppingCart ul {
    list-style-type: none;
    padding: 0;
}

.shoppingCart li {
    margin-bottom: 5px;
}
.cartSib {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding-bottom: 20px;
}
.sibImage img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    object-position: top;
}
.sibContent {
    width: 75%;
}
.sibContent p {
    padding: 10px 0px;
    font-size: 12px;
    color: #505050;
}
.sibContent h3 {
    margin: 0px;
}
.sibImage {
    width: 25% !important;
}
.cartSibBtn {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100% !important;
}
.cartSibBtn p {
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 12px;
}
.cartHeading {
    text-align: center;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #eaeaea;
    padding: 20px 0px;
}
.cartHeading img {
    position: absolute;
    bottom: 0px;
    top: 0;
    height: 30px;
    left: -15px;
    margin: auto;
    cursor: pointer;
}
.cartSibBtn p:nth-child(1) {
    border: 1px solid #0b9c7f;
    color: #0b9c7f;
}
.cartSibBtn p:nth-child(2) {
    border: 1px solid #0b9c7f;
    background-color: #0b9c7f;
    color: white;
}
.selectedBox {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
}
.selectedBox img {
    width: 100%;
    height: 100px;
    object-fit: cover;
}
.selectedImg,
.selectedPrice {
    width: 25%;
}
.selectedName {
    width: 50%;
}
.selectedPrice span {
    display: block;
    font-weight: bold;
    font-size: 12px;
    padding-left: 10px;
}
.selectedName p {
    font-size: 12px;
    color: #505050;
}
.selectedImg img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
}
