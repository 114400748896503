.storeBck {
    background-color: #ff7628;
    height: 500px;
}
.store {
    background-image: url('../../../public/image/gr.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 700px;
    margin-top: -450px;
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.showMessage {
    display: none;
}
.newsText {
    width: 40%;
    margin: auto;
}
.store h2 {
    font-size: 56px;
}
.storeText {
    width: 45%;
    margin: 0 auto;
}
.hideMessage {
    display: block;
}
.store a {
    color: #f9de2e;
    font-weight: 600;
}
.store form div {
    background-color: white;
    width: 600px;
    margin: auto;
    padding: 5px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
}

.store p {
    padding-bottom: 30px;
}
.store button {
    background-color: #d9493a;
    color: white;
    width: 30%;
    border: unset;
    padding: 20px;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}
.storeSecondBck {
    /* background-color: #fff8ef; */
    padding-bottom: 50px;
}
.store a {
    background-color: #d9493a;
    color: white;
    padding: 20px;
    border-radius: 50px;
    margin-bottom: 20px;
}
.store input {
    width: 65%;
    border: unset;
    padding: 15px;
    border-radius: 20px;
}
.store input::placeholder {
    color: black;
}
.submitMessage {
    padding: 50px 0px;
}
.store h3 {
    font-size: 38px;
}
@media (max-width: 800px) {
    .storeText {
        width: 100%;
        /* margin: auto; */
    }
    .store {
        padding: 0px 20px;
    }
    .store h2 {
        font-size: 32px;
    }
    .store h3 {
        font-size: 28px;
    }
    .store form div {
        width: 100%;
        /* flex-direction: column; */
    }
    .store input {
        width: 65%;
    }
    .hideMessage {
        width: 100%;
    }
    .store button {
        padding: 10px;
        font-size: 14px;
        width: 40%;
    }
}
