.toggleContainer {
    margin: 20px;
    font-family: Arial, sans-serif;
}

.toggleButton {
    position: relative;
    width: 60px;
    height: 30px;
    background-color: #ccc;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50px;
}

.circle {
    position: absolute;
    top: 0;
    left: 3px;
    right: 0;
    bottom: 0;
    margin: auto 0px;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    filter: drop-shadow(2px 1px 6px rgba(0, 0, 0, 0.25));
}

.toggled .circle {
    transform: translateX(30px);
    right: 5px;
}
.toggleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.choice span {
    font-size: 10px;
    background-color: #fbff4b;
    padding: 2px 10px;
    border-radius: 50px;
    font-weight: 700;
}
.choice p {
    font-weight: 700;
    text-align: center;
}
.toggleBox label div {
    display: flex;
    align-items: center;
    background: #d6f2e5;
    padding: 20px 20px 20px 10px;
}
.toggleBox input {
    margin: 0px;
}
.toggleBox label {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.toggleBox p {
    padding: 10px;
    font-size: 14px;
}
.toggleBox {
    border-radius: 5.316px;
    border: 0.665px solid rgba(77, 77, 77, 0.2);
    background: #fff;
    box-shadow: 0px 1.329px 2.658px 0px rgba(77, 77, 77, 0.2);
    position: relative;
}
.toggleBox span {
    background-color: var(--main);
    position: absolute;
    font-size: 10px;
    color: white;
    top: -7px;
    left: 120px;
    padding: 2px 10px;
    border-radius: 70px;
    font-weight: 700;
}
.toggle {
    display: flex;
    gap: 20px;
}

.toggleImage img {
    width: 100%;
}
.free {
    color: #ea5443;
    font-size: 12px;
    font-weight: 700;
}
.toggleContent p {
    padding-bottom: 20px;
    font-size: 12px;
}
.toggleContent h3 {
    font-size: 18px;
    margin: 0px;
    font-weight: 700;
    padding-top: 10px;
}
.toggleBottom {
    text-align: left;
    font-size: 12px;
}
.toggle a {
    background-color: var(--main);
    padding: 10px;
    width: 207px;
    border-radius: 70px;
    color: white;
}
.toggle a img {
    filter: invert(100%);
}
.toggleBoxes {
    border-radius: 10px;
    border: 1px solid #efefef;
    padding-bottom: 15px;
    background: #fff;
    position: relative;
}
.toggleDis {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    align-items: baseline;
    column-gap: 10px;
}
.planDis {
    grid-template-columns: repeat(3, 1fr);
}
.toggleContent {
    background: #f6e7a6;
    padding: 15px;
    border-radius: 10px;
}
.toggleContent div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}
.toggleContent span {
    font-size: 12px;
    color: white;
    padding: 2px 5px;
}
.toggleContent span:nth-child(1) {
    border-radius: 10px;
    background: #0b9c7f;
}
.toggleContent span:nth-child(2) {
    border-radius: 10px;
    background: #ea5443;
}
.openOption p {
    text-align: center;
    font-size: 14px;
    padding: 10px 0px;
    border-bottom: 1px solid #dae2e5;
    width: 90%;
    margin: auto;
}
.openOption {
    display: flex;
    flex-direction: column;
}
.openOption img {
    width: 20px;
    margin: 10px auto;
}
.openOption li {
    font-size: 14px !important;
    color: #505050;
    font-weight: 500;
}
.openOption li {
    width: 70%;
    margin: auto;
    list-style-image: url('../../../public/image/icon/togtick.svg');
}
.openOption button {
    background-color: #ea5443;
    width: 75%;
    margin: auto;
    border: unset;
    border-radius: 50px;
    padding: 10px;
    color: white;
    cursor: pointer;
    transition: 0.3s;
}
.openOption button:hover {
    background-color: #0b9c7f;
}
.openOption img {
    cursor: pointer;
}
.showList {
    max-height: 1000px;
    /* opacity: 1; */
    overflow: hidden;
    margin: 0px;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.hideList {
    max-height: 0;
    /* opacity: 0; */
    overflow: hidden;
    margin: 0px;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.code {
    position: absolute;
    top: -28px;
    width: 95%;
    margin: auto;
    background-color: #0b9c7f;
    color: white;
    font-size: 14px;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
    left: 0;
    right: 0;
    text-align: center;
}
.code span {
    color: #f9cc0b;
}
.arrowHide {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
}
.arrowList {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}
.colorOne {
    background-color: #ffddc7;
}
.colorTwo {
    background-color: #e1ffc9;
}
.colorThree {
    background-color: #ffe4e1;
}
.colorBorder {
    border: 2px solid #258d8f;
}
.planHeading {
    text-align: center;
}
.planHeading img {
    height: 30px;
}
.planHeading span {
    color: #ea5443;
}
.planHeading p {
    font-weight: 700;
    width: 30%;
    margin: auto;
}
.prepaidDis {
    grid-template-columns: repeat(2, 1fr);
    width: 60%;
    margin: auto;
}
.siblings,
.addon {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 35px 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    text-align: left;
    gap: 20px;
}
.siblings h3 {
    font-size: 18px;

    color: #258d8f;
    text-align: center;
}

.siblings h1,
.addon h1 {
    font-size: 24px;
    color: #258d8f;
}
.siblings p {
    font-size: 14px;
    padding-bottom: 20px;
}
.sibButton,
.buttonDis {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
}
.buttonDis {
    gap: 10px;
    padding-top: 20px;
}
.buttonDis button:nth-child(1) {
    background-color: #f0c161;
    color: white;
    font-size: 18px;
    padding: 15px 20px;
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
    border: unset;
}
.buttonDis button:nth-child(2) {
    border: 1px solid #159888;
    color: #159888;
    background-color: unset;
    font-size: 18px;
    padding: 15px 20px;
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
}
.sibButton div {
    border: 1px solid #359597;
    border-radius: 50px;
    text-align: center;
    padding: 10px;
    cursor: pointer;
}
.sibButton h3,
.sibButton p {
    margin: 0px;
    padding: 0px;
    cursor: pointer;
}
.sibButton div p {
    font-size: 12px;
    color: #7a7a7a;
}
.noThanks {
    padding-top: 10px !important;
}
.sibImage,
.sibContent {
    width: 100%;
}
.sibImage img {
    width: 100%;
}
.plancont {
    width: 50%;
}
@media (max-width: 1024px) {
    .toggleDis {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 50px;
    }
}
@media (max-width: 800px) {
    .toggle {
        flex-direction: column;
    }
    .toggleImage,
    .toggleContent {
        width: 100%;
    }
    .toggleDis {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 50px;
    }
    .planHeading p {
        width: 100%;
    }
    .toggleContainer {
        width: 100%;
        margin: 20px 0px;
    }
    .toggleDis {
        padding-top: 40px !important;
    }
    .planHeading h1 {
        padding-bottom: 20px;
    }
    .prepaidDis {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        margin: auto;
    }
}
