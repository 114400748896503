.testimonials h1 {
    text-align: center;
    padding-bottom: 20px;
    z-index: 99;
    position: relative;
}
.testimonials {
    background: #effaf9;
    padding: 30px 0px;
}
.testimonialsBox {
    text-align: center;
}
.testimonials div {
    z-index: 999;
}
.contentBox {
    position: relative;
    height: 250px;
}
.verified {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
    padding-bottom: 10px !important;
}
.deerBck {
    background-color: #77c3a4;
    position: relative;
}
.deer {
    position: absolute;
    left: 0px;
    bottom: 0px;
    /* height: 200px; */
    width: 350px !important;
}
.deerTwo {
    position: absolute;
    right: 0px;
    top: 0px;
    /* height: 200px; */
    width: 400px !important;
}
.verified img {
    height: 15px;
    width: 15px !important;
    margin-left: 5px;
}
.stars {
    position: absolute;
    top: -15px;
    padding: 5px 10px !important;
    width: fit-content;
    margin: auto;
    border-radius: 3px !important;
    background: #fff;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    left: 0;
    right: 0;
}
.stars img {
    height: 20px;
}
.testimonials h2 {
    font-size: 18px;
}
.testimonials img {
    width: 100%;
    display: block;
}
.testimonialsBox div {
    background-color: white;
    padding: 30px 10px 30px 10px;
    border-radius: 0px 0px 10px 10px;
}
.testimonialsBox {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}
.testimonialsBox p {
    font-size: 14px;
    font-weight: 700;
}
.linkBox {
    width: 493px;
    border-radius: 12.579px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 25px;
    margin: auto;
    gap: 20px;
    margin-top: 20px;
    z-index: 99;
    position: relative;
}
.linkBox a {
    background-color: var(--main);
    color: white;
    border-radius: 50px;
    padding: 10px 15px;
    display: block;
    width: 120px;
    text-align: center;
    transition: 0.3s;
}
.linkBox a:hover {
    background-color: #0b9c7f;
}
.linkBox p {
    padding: 10px 0px;
    font-weight: 700;
    font-size: 14px;
}
.linkBox h3 {
    margin: 0px;
    color: var(--main);
    font-size: 18px;
}
.linkBox img {
    width: 120px;
}
@media (max-width: 800px) {
    .linkBox {
        width: 90%;
        margin: auto;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .linkBox a {
        margin: auto;
    }
    .linkBox {
        margin-top: 50px;
    }
    .contentBox {
        position: relative;
        height: 200px;
    }
}
