.fanHeading {
    text-align: center;
    width: 30%;
    margin: auto;
    padding-bottom: 50px;
}
.fanHeading span {
    color: var(--main);
    font-weight: bold;
}

.boxesHeading {
    display: flex;
    justify-content: space-between;
}
.boxesHeading a {
    color: #0b9c7f;
    font-weight: 500;
}
.boxesHeading h2 {
    color: var(--main);
}
.box img {
    height: 200px;
}
.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border: 1px solid #f9f7f9;
    border-radius: 20px;
    padding: 10px;
}
.box div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
/* .stock {
    position: absolute;
    color: var(--main);
    background-color: #fff0f0;
    border-radius: 50px;
    padding: 8px 12px;
    top: 10px;
    right: 10px;
    font-weight: 700;
    font-size: 14px;
} */
.box p {
    font-weight: bold;
}
.box h4 {
    font-weight: 400;
    color: #667085;
}
.fanBoxDis {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    column-gap: 10px;
    padding-top: 20px;
}
.box a {
    background-color: #0b9c7f;
    border-radius: 50px;
    padding: 8px;
    color: white;
    width: 90%;
    margin: auto;
    display: block;
    text-align: center;
}
.fan {
    padding-top: 50px;
}

.storeDis {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    column-gap: 20px;
    row-gap: 20px;
    padding-bottom: 50px;
    padding-top: 20px;
}
.dicount span {
    text-decoration: line-through;
    color: red;
    text-align: left;
    margin: 0px;
}
.dicount p {
    padding-bottom: 0px !important;
    font-size: 20px !important;
}
.dicount i {
    padding-bottom: 20px;
}
.dicount {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: unset !important;
    width: fit-content;
    margin: 0px auto;
    padding: 0px !important;
    text-align: center;
}
.stock {
    font-size: 16px !important;
    color: red !important;
}
.storeDis img {
    /* width: 100%; */
    height: 200px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin: auto;
}
.storeDis div {
    padding: 20px;
    text-align: center;
    border-radius: 20px;
    background-color: #e3f3ec;
}
.storeDis h3 {
    font-weight: 400;
    margin: 0px;
    font-size: 26px;
    padding: 20px 0px;
}

.storeDis p {
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 20px;
}
.storeDis i {
    color: #9ba4a0;
    font-weight: 500;
}
.storeDis a {
    border-radius: 8px;
    border: 2px solid #258d99;
    color: #258d99;
    padding: 8px 18px;
    background-color: white;
    font-weight: bold;
    font-size: 18px;
}
@media (max-width: 800px) {
    .fanHeading {
        width: 100%;
    }
    .fanBoxDis,
    .storeDis {
        grid-template-columns: repeat(1, 1fr);
    }
}
