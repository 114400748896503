.missing {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 0px;
    justify-content: center;
}
.missing p {
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    width: 60%;
}
@media (max-width: 800px) {
    .missing p {
        width: 100%;
    }
}
