.trusted {
    text-align: center;
    z-index: 999;
    position: relative;
}
.trustedBox {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    padding-top: 40px;
    width: 90%;
    margin: auto;
}
.trustedBoxes {
    border-radius: 10px;
    /* border: 1px solid #258d8f; */
    background: #fff7ef;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    padding: 15px 15px 30px 15px;
}
.trustedBox a {
    /* padding-top: 20px; */
    color: #ea5443;
    font-weight: 500;
}
.orange {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.orange img {
    height: 10px !important;
}
/* .trustedBox p {
    padding-bottom: 20px;
} */
.trustedBox img {
    height: 100px;
}
.trustedBox h3 {
    color: #258d8f;
    font-size: 18px;
    margin: 0px;
    padding-bottom: 10px;
}
.trustedBox p {
    font-size: 12px;
    font-weight: 700;
}
@media (max-width: 1024px) {
}
@media (max-width: 800px) {
    .trustedBox {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        row-gap: 10px;
    }
    .trustedBox img {
        height: 80px;
    }
    .trustedBoxes {
        display: flex;
        text-align: left;
        align-items: center;
        gap: 20px;
        padding: 15px;
    }
    .orange {
        justify-content: flex-start;
    }
}
