.faqItem {
    margin: 10px auto;
    overflow: hidden;
    width: 100%;
    transition: max-height 0.3s ease;
    text-align: left;
    color: white;
    transition: 0.3s;
    /* box-shadow: */
    background: #fff7ef;
    border-radius: 8px;
    transition: 0.3s;
    border: 2px solid #efefef;
}

.question {
    display: flex;
    background: #fff7ef;
    color: black;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    /* margin-bottom: 5px; */
    font-weight: bold;
    font-size: 16px;

    cursor: pointer;
    transition: background-color 0.3s ease;
}

.faqItem:hover {
    border: 2px solid #258d8f;
}

.questionText {
    flex-grow: 1;
}

.arrow {
    font-size: 18px;
    transition: transform 0.3s ease;
    width: 15px;
    height: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.arrowUp {
    content: url('../../../public/image/icon/bl.svg');
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;
}
.arrowDown {
    content: url('../../../public/image/icon/bl.svg');

    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
}
.answer {
    /* background-color: #dbe9ff; */
    overflow: hidden;
    background: #fff7ef;
    padding: 0px 1rem;
    transition: max-height 0.3s ease;
}
.faqHeading {
    padding-top: 50px;
    text-align: left;
}
.faqBck,
.faqContainer {
    /* background-color: hsl(192, 100%, 42%, 0.1); */
    margin-top: 20px;
    /* padding: 30px 50px; */
    text-align: center;
    width: 100%;
    border-radius: 18px;
}
.faqBck {
    margin-bottom: 50px;
}
.answer.open {
    max-height: 1000px; /* Adjust the value as needed */
}
.active {
    border: 2px solid #258d8f;
    border-radius: 18px;
    padding-bottom: 20px;
}
.answerText {
    color: black;
}

@media (max-width: 800px) {
    .faqItem {
        width: 100%;
        font-size: 11px;
    }
    .question {
        padding: 20px;
    }
    .questionText {
        width: 70%;
        font-size: 18px;
    }
    .answerText {
        font-size: 14px;
        text-align: left !important;
    }
    .faqBck,
    .faqContainer {
        padding: 20px 0px;
    }
    .arrow {
        font-size: 14px;
        width: 15px;
        height: 15px;
        transition: transform 0.3s ease;
    }
    .faqBck p {
        text-align: center;
    }
}
