.banner {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
}
.bannerContent {
    width: 45%;
    background-color: var(--sub-main);
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    background-image: url('../../../public/image/pattern.svg');
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    text-align: left;
    padding: 50px;
    position: relative;
    z-index: 9999;
}
.trustedBck {
    background-color: #fff7ef;
}
.homeDes {
    display: flex;
    align-items: center;
    width: 55%;
}
.banner h1 {
    font-size: 38px;
    color: white;
}
.banner p {
    color: white;
    font-size: 24px;
    padding: 20px 0px;
}
.banner img {
    height: 400px;
}
.buttonDis {
    display: flex;
    align-items: center;
}
.buttonDis a {
    margin: 5px;
}
.learnBoxes {
    /* display: grid;
    column-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px; */
    display: flex;

    /* flex-wrap: wrap; */
    gap: 10px;
    justify-content: center;
}
.learnBoxes iframe {
    width: 45%;
    border-radius: 20px;
}
.learnBoxes div {
    width: 45%;
}
.learnDis .learnBoxes:nth-child(1) {
    padding-bottom: 10px;
}
.learnBck {
    background-image: url('../../../public/image/bck.webp');
    object-fit: cover;
    border-radius: 20px;
    position: relative;
}
.closeVid {
    display: none;
}
.openVid {
    display: block;
}
.learnBck div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 186px;
    text-align: center;
    padding: 10px;
    border-radius: 50px;
    color: white;
    background-color: #ea5443;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    height: 50px;
    cursor: pointer;
}
.learnBckTwo img {
    width: 100%;
    height: 100%;
}
.learnBox,
.learnBoxTwo {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    padding: 50px;
}
.learnBox {
    background: #258d8f;
}
.learnBoxTwo {
    background-color: #ea5443;
}
.learnBox span,
.learnBoxTwo span {
    color: #ccff4a;
    font-size: 14px;
}
.learnBox h3,
.learnBoxTwo h3 {
    color: white;
    font-size: 25px;
    margin: 0px;
}
.learnBox p,
.learnBoxTwo p {
    padding: 10px 0px;
    color: white;
}
.learnHeading {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.learnHeading {
    font-weight: 800;
}
.learnHeading h1 {
    color: #258d8f;
}
.learnHeading p {
    padding: 20px 0px;
    font-weight: 700;
    width: 80%;
}
.learnHeading span {
    color: #ea8442;
}
.learn {
    margin-bottom: -100px;
    /* padding-bottom: 100px; */
    background: #fefff0;
    /* background-image: url('../../../public/image/bc.png'); */
    background-repeat: no-repeat;
    background-size: cover;
}
.homeMob {
    display: none;
}
.buttonV p {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #fbff4b;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 50px;
    color: black;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.buttonV img {
    height: 15px;
    /* filter: invert(100%); */
}
.mobileMonth {
    display: none;
    font-weight: 600;
}
.desMonth {
    font-weight: 600;
    font-size: 16px !important;
}
.drepos,
.pingpos {
    position: absolute;
    z-index: 99;
}
.drepos {
    /* width: 60px; */
    height: 250px !important;
    right: 0px;
    bottom: 0px;
}
.pingpos {
    height: 150px !important;
    left: 0px;
    top: 20px;
}
.bannerContentHoliday {
    background-image: url('../../../public/image/snoww.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #76c7b4;
}
.swiperMob {
    display: none !important;
}
@media (max-width: 1024px) {
    .banner {
        flex-direction: column;
    }
    .bannerContent {
        width: 100% !important;
    }
    .banner div:nth-child(2) {
        justify-content: center;
    }
    .homeDes {
        width: 100%;
    }
    .banner img {
        width: 100%;
        height: unset;
    }
    .homeDes {
        display: none !important;
    }
    .homeMob {
        display: block;
    }
    .bannerContent {
        justify-content: center;
        text-align: center;
    }
    .buttonDis {
        justify-content: center;
    }
    .buttonDis img {
        width: 10px !important;
    }
}
@media (max-width: 800px) {
    .homeDes {
        justify-content: center;
        flex-direction: column;
    }
    .bannerContent {
        padding: 20px;
    }
    .banner img {
        width: 100%;
        height: auto;
    }

    .learnDis div {
        width: 100%;
    }
    .learnDis .learnBoxes:nth-child(1) {
        flex-direction: column-reverse;
    }
    .learnBoxes {
        gap: 0px;
    }
    .learnBoxes div {
        border-radius: 0px 0px 20px 20px;
    }
    .learnBck,
    .learnBckTwo img {
        border-radius: 20px 20px 0px 0px !important;
    }
    .learnBoxTwo,
    .learnBox {
        padding: 20px;
    }
    /* .banner img:nth-child(1) {
        display: none;
    } */

    .homebck {
        background-color: var(--sub-main);
        background-image: url('../../../public/image/pattern.svg');
        background-size: cover;
        background-repeat: no-repeat;
        justify-content: center;
    }
    .bannerContent {
        background-image: unset;
        background-color: unset;
    }
    .buttonDis {
        flex-direction: row;
        align-items: normal;
    }
    .banner h1 {
        text-align: center;
        font-size: 32px;
    }
    .desMonth {
        display: none;
    }
    .mobileMonth {
        display: block;
    }
    .banner p {
        text-align: center;
        padding: 10px 0px;
        font-size: 16px;
    }
    .buttonDis a {
        justify-content: center;
    }

    .homeMob img {
        width: 100%;
    }
    .learnBoxes iframe {
        width: 100%;
        border-radius: 20px 20px 0px 0px;
    }
    .learnHeading p {
        width: 100%;
    }
    .drepos {
        height: auto !important;
        width: 100px !important;
        right: -20px;
    }
    .pingpos {
        height: auto !important;
        width: 50px !important;
        left: -20px;
    }
    .homebck {
        background-image: url('../../../public/image/snoww.png') !important;
        background-color: #76c7b4;
    }
}
