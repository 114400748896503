.journeyBck {
    background-color: #e95242;
    padding-top: 150px;
    padding-bottom: 100px;
}
.journeyBck h1 {
    color: white;
}
.journeyBox {
    display: flex;
    align-items: center;
    gap: 50px;
    background-color: white;
    border-radius: 20px;
    padding: 20px 50px;
}
.journeyBox div:nth-child(2) {
    display: flex;

    flex-direction: column;
    text-align: left;
    align-items: flex-start;
}
.journeyBox h1 {
    color: #514f4e;
    padding: 10px 0px;
}
.journeyBck img {
    height: 150px;
}
.journeyBck div:nth-child(3) {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
}
.journeyBox span {
    background-color: #fbff4b;
    color: #514f4e;
    font-size: 16px;
    padding: 5px 10px;
    font-weight: 800;
    border-radius: 50px;
}
.journeyDis {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 50px;
}
.journeyBox a {
    color: white;
}
@media (max-width: 800px) {
    .journeyBox {
        flex-direction: column;
        padding: 20px;
        gap: 10px;
    }
    .journeyBox span {
        font-size: 14px;
    }
    .journeyBox div:nth-child(2) {
        text-align: center;
        align-items: center;
    }
}
