.newsletter {
    padding-top: 30px;
    background-color: #e95242;
    text-align: center;
    color: white;
    padding-bottom: 30px;
    background-image: url('../../../public/image/newsBck.png');
    background-repeat: repeat;
    background-size: contain;
}
.newsDis {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.newsDis span {
    font-size: 12px !important;
}
.newsDis img {
    width: 80%;
}
.increase {
    padding-top: 150px !important;
}
.zooColor {
    background-color: var(--sub-main);
}
.newsletter form {
    /* background-color: white; */
    width: 600px;
    margin: auto;
    padding: 5px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.newsletter form a {
    color: white;
    text-decoration: underline;
}
.newsletter h4 {
    background-color: #fbff4b;
    color: black;
    width: fit-content;
    margin: 0px;
    padding: 6px 20px;
    border-radius: 50px;
    margin: auto;
    font-size: 16px;
}
.newsletter h1 {
    padding: 10px 0px;
}

.showMessage {
    display: none;
}
.newsText {
    width: 70%;
    margin: auto;
}
.hideMessage {
    display: block;
}
.newsletter p {
    padding-bottom: 10px;
}
.newsletter button {
    background-color: #0b9c7f;
    color: white;
    width: 30%;
    border: unset;
    padding: 15px 20px;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 700;
    width: 65%;
}
.newsletter input {
    width: 65%;
    border: unset;
    padding: 15px;
    border-radius: 20px;
}
.newsletter input::placeholder {
    color: black;
}
.submitMessage {
    padding: 50px 0px;
}
@media (max-width: 800px) {
    .newsletter form div {
        width: 100%;
    }
    .newsletter button {
        padding: 10px;
    }
    .newsText {
        width: 100%;
    }
    .newsDis {
        flex-direction: column;
    }
    .newsDis img {
        width: 100%;
    }
    .newsDis div {
        width: 100%;
    }
    .newsDis form {
        width: 100%;
    }
    .newsDis input,
    .newsDis button {
        width: 100%;
    }
}
