.filters {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    /* position: fixed; */
    padding: 10px;
}
.filtersSection {
    /* position: sticky; */
    left: 0;
    top: 0;
}

.filterSection {
    margin-bottom: 10px;
    /* border: 1px solid #ddd; */
    width: 200px;
    border-radius: 5px;
    overflow: hidden;
}

.filterHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    /* padding: 15px; */
    padding: 0px 10px;
    /* background-color: #f4f4f4; */
    transition: background-color 0.3s;
}
/* 
.filterHeader:hover {
    background-color: #e0e0e0;
} */

.arrow {
    font-size: 18px;
}

.filterOptions {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0;
}

.filterOptions label {
    display: flex;
    padding: 10px;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
}

.filterOptions label:last-child {
    border-bottom: none;
}

.open {
    max-height: 300px; /* Increase as per the content size */
    opacity: 1;
}

.closed {
    max-height: 0;
    opacity: 0;
}
.productList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    column-gap: 10px;
}
.productsSection {
    width: 80%;
}
.shopBoxVThree {
    padding: 20px;
    text-align: left;
    border-radius: 20px;
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    /* justify-content: space-between; */
    text-align: center;
}
.productStock {
    background-color: #fff0f0;
    color: #d9493a;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    right: 20px;
    top: 20px;
}
.ZooColor {
    color: var(--sub-main);
    text-align: center;
}
.productAge {
    background-color: var(--sub-main);
    color: white;
    padding: 3px 15px;
    border-radius: 50px;
    font-size: 10px;
    font-weight: 700;
    /* position: absolute; */
    text-align: center;
    width: fit-content;
    margin: auto;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    position: absolute;
    top: 40px;
}
.cubAge {
    background-color: var(--main);
}
.productAge img {
    height: 20px;
    width: 20px;
}
.mainImage {
    /* margin-bottom: 20px; */
    width: 100%;
    border-radius: 20px;
    /* height: 200px; */
    height: 230px;
    object-fit: contain;
}
/* .priceDis h3 {
    min-height: 50px;
} */
.edventurePage {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    position: relative;
}

.starContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    background-color: white;
    border-radius: 12px !important;
    width: 120px !important;
    border-radius: 4px;
    width: fit-content;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    width: 60%;
    padding: 5px 0px;
    margin: auto;
    width: fit-content;
    border-radius: 2px;
}
.priceDis p {
    /* border-right: 1px solid #e0e0e0; */
    width: 100%;
    /* padding-right: 5px; */
    padding: 2px 5px;
    text-align: center;
    font-weight: 600;
}
.starContainer div {
    width: 50%;
    display: flex;
    justify-content: center;
    gap: 2px;
}
.starContainer img {
    width: 15px;
    height: 15px;
}
.starContainer div p {
    font-size: 14px;
    color: #344054;
    border: none;
}
.arrUp img,
.arrDow img,
.arrow img {
    transition: transform 0.5s ease-in-out;
}
.firePopular {
    text-align: center;
    background-color: white;
    padding: 4px 20px;
    width: fit-content;
    margin: auto;
    border-radius: 12px;
    margin-top: 20px;
    color: var(--sub-main);
}
.allShop {
    background-color: var(--sub-main);
    padding: 10px 25px;
    border-radius: 50px;
    color: white;
    width: 180px;
    text-align: center;
    display: block;
    margin: auto;
}
.addToCart {
    background-color: #0b9c7f;
    text-align: center;
    color: white;
    padding: 8px 20px;
    width: fit-content;

    border-radius: 50px;
    margin: auto;
    display: block;
    margin-top: 10px;
    transition: 0.3s;
}
.addToCart:hover {
    background-color: #000;
}
.arrUp img {
    transform: rotate(180deg);
}

.arrDow img {
    transform: rotate(180deg);
}
.arrow img {
    width: 15px;
}
.edventureBanner {
    height: 300px;
    margin-top: -120px;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #fff8f4;
    border-bottom: 3px solid #ff7628;
    text-align: center;
    color: #ff7628;
    border-radius: 0px 0px 200% 200%;
}
.agesIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.discountPrice {
    text-decoration: line-through;
    color: red;
}
.agesIcon img {
    height: 22px;
}
.filters label {
    display: flex;
    align-items: center;
    gap: 5px;
}
.filters input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0px;
    cursor: pointer;
    box-shadow: 0px 0px 0px 1px transparent inset !important;
    border-radius: 6px !important;
    background: rgb(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
    position: relative;
}
.filters input[type='checkbox']:checked {
    background-color: white;
    border: 1px solid green;
}

/* The green checkmark */
.filters input[type='checkbox']:checked::before {
    content: '✓';
    font-size: 1.2rem;
    color: green;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.animalFun h2 span {
    color: var(--sub-main);
}
.animalFun h2 {
    text-align: center;

    font-size: 32px;
}
.animalFunBox div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border: 1px solid #efefef;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    z-index: 99;
    position: relative;
}
.dropdownMenu {
    background-color: #f3fffc;
    text-align: left;
    width: 640px;
    margin: auto;
    padding: 20px;
    padding-top: 20px;
    border-radius: 8px;
}
.dropdownMenu a {
    font-size: 22px;
    color: black;
    transition: 0.3s;
    padding-bottom: 10px !important;
    display: block;
}
.dropdownMenu a:hover {
    color: #0b9c7f;
}
.animalFunBox h3 {
    font-size: 32px;
    text-align: center;
    margin: 0px;
}
.animalFunBox h3 span {
    color: var(--main);
    display: block;
}
.Zoologist h3 span {
    color: var(--sub-main);
}
.animalFunBox div img {
    height: 150px;
    margin-top: -70px;
}
.animalFunBox div p {
    background-color: #fbff4b;
    color: #514f4e;
    font-weight: 600;
    text-align: center;
    padding: 5px 15px;
    border-radius: 50px;
}
.funShop {
    background-color: var(--main);
    color: white;
    text-align: center;
    font-weight: 600;
    padding: 12px;
    padding-top: 40px;
    border-radius: 20px;
    margin-top: -32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.funShop img {
    width: 15px;
}
.Zoologist .funShop {
    background-color: var(--sub-main) !important;
}
.animalFunDis {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin: auto;
    padding-top: 80px;
    column-gap: 10px;
}
.animalFunContainer {
    width: 600px;
    margin: auto;
    padding: 50px 0px;
}

.inspire {
    background-color: #ffeddd;

    width: 100%;
    padding: 20px 0px;
}
.inspire h2 {
    text-align: center;
    width: 600px;
    font-size: 32px;
    margin: auto;
}
.searchDis {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
}
.searchDis p {
    font-weight: 600;
}
.searchDis select {
    border: unset;
    border-bottom: 1px solid black;
}

.customDropdown {
    position: relative;
    cursor: pointer;
}

.selectedOption {
    padding: 10px;

    border: 2px dotted black;
    background: white;
    border-radius: 12px;
    /* border-radius: 5px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.optionsList {
    position: absolute;
    top: 100%;
    left: -42px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 10;
    overflow: hidden !important;
    max-height: 170px;
    width: 200px;
    overflow-y: auto;
}
.optionsListTwo {
    max-height: 300px;
    left: -20px;
}
.digitalBck {
    background-color: #f3fffc;
    padding: 50px 0px;
    display: flex;
    align-items: center;
}
.digitalContent {
    width: 30%;
    padding-left: 180px;
}
.digitalContent h3 {
    margin: 0px;
    font-size: 32px;
    color: #258d8f;
}
.digitalContent p {
    padding: 10px 0px;
    font-size: 600;
}
.digitalContent a {
    display: block;
    background-color: var(--sub-main);
    padding: 10px;
    border-radius: 50px;
    width: 200px;
    text-align: center;
    color: white;
}
.digitalDis {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 50px;
}
.digitalSwiper {
    width: 70%;
}
.option {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.selectedOption img {
    width: 25px;
    margin-right: 10px;
}
.option img {
    width: 25px;
    margin-right: 10px;
}

.option:hover {
    background-color: #f0f0f0;
}

.arrowAll {
    margin-left: 10px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
}
.searchProduct {
    text-align: center;
    padding: 50px 0px;
}
.searchDis p {
    font-size: 24px;
}
.searchbck {
    background-image: url('../../../public/image/bckgr.png');
    background-repeat: repeat;
    background-size: contain;
}
.searchProduct h2 {
    padding-bottom: 20px;
    font-size: 32px;
}

.searchIcon {
    background-color: #258d8f;
    padding: 5px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    border-radius: 5px;
    cursor: pointer;
}
.shopBanner img {
    width: 100%;
}
.foundersBck {
    background-image: url('../../../public/image/bckc.png');
    background-repeat: repeat;
    background-size: contain;
    width: 100%;
    padding: 50px 0px;
    padding-bottom: 150px;
    margin-bottom: -200px;
}
.foundersBck img {
    width: 600px;
    margin: auto;
}
.foundersBck div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.foundersBck p {
    font-size: 38px;
    font-weight: 600;
    color: #514f4e;
    width: 700px;
    margin: auto;
    text-align: center;
}
.facebookLink {
    color: #514f4e;
}
.foundersBck p span {
    color: var(--main);
}
.didYou {
    background-color: #258d8f;
    color: white;
    display: block;
    padding: 12px 20px;
    border-radius: 50px;
    margin-bottom: 20px;
}

.swiperMob {
    display: none;
}
.filterButton,
.closeButton {
    display: none;
}
.noScroll {
    overflow: hidden;
}
.edventureBanner div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 10px;
}
.edventureBanner p {
    border-radius: 20px;
    padding: 8px 25px;
    font-weight: 500;
}
.edventureBanner p:nth-child(1) {
    background-color: #248789;
    color: white;
}

.edventureBanner p:nth-child(2) {
    background-color: #a3d8c1;
    color: #248789;
    /* padding: 5px 12px; */
}
@media (max-width: 1200px) {
    .productList {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .edventurePage {
        flex-direction: column;
    }
    .filterButton {
        display: flex !important; /* Show on mobile */
        align-items: center;
        /* justify-content: center; */
        width: fit-content;
        gap: 10px;
    }
    .filterButton img {
        width: 25px;
    }

    .productList {
        grid-template-columns: repeat(2, 1fr);

        row-gap: 10px;
    }
    .filters {
        display: flex;
        gap: 10px;
        padding: 0px;
    }
    .swiperDes {
        display: none;
    }
    .swiperMob {
        display: block;
    }
    .filterHeader {
        padding: 0px 5px;
    }
    .filters h3 {
        font-size: 14px;
    }
    .productAge {
        position: relative;
        top: 0px;
        font-size: 10px;
    }
    .agesIcon img {
        display: none;
    }
    .animalFunDis {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 70px;
    }
    .animalFunContainer {
        width: 100%;
        padding: 0px 20px;
    }
    .animalFun {
        padding-top: 40px;
    }
    .searchProduct {
        padding: 50px 20px;
    }
    .inspire {
        width: 100%;
    }
    .inspire h2 {
        width: 100%;
        padding: 0px 20px;
    }
    .mainImage {
        height: 150px;
    }
    .digitalDis {
        flex-direction: column;
    }
    .digitalContent,
    .digitalSwiper {
        width: 90%;
    }
    .digitalContent {
        padding-left: 0px;
    }
    .foundersBck img {
        width: 100%;
    }
    .foundersBck p {
        width: 100%;
        font-size: 20px;
        padding-bottom: 20px;
    }
    .productsSection {
        width: 100%;
    }
    .searchDis {
        flex-direction: column;
        align-items: flex-end;
    }
    .searchDis p {
        text-align: left;
        width: 100%;
    }
    .customDropdown {
        margin-bottom: 20px;
        width: 100%;
    }

    .filterButton {
        background-color: #258d8f;
        color: white;
        padding: 8px 20px;
        width: 100%;
        border-radius: 50px;
        border: none;
        cursor: pointer;
        font-size: 18px;
        display: block;
        justify-content: center;
        display: flex;
        /* margin: 10px auto; */
    }

    .filtersPopup {
        position: fixed;
        bottom: -100%; /* Initially hidden */
        left: 0;
        width: 100%;
        height: 60%;
        background: white;
        transition: bottom 0.3s ease-in-out;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
        z-index: 101;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .openPopup {
        bottom: 0; /* Slide-up effect */
    }

    .filtersContent {
        max-height: 100%;
        overflow-y: auto;
        flex-grow: 1;
    }
    .filtersContent {
        flex-direction: column;
        overflow-y: scroll;
    }
    .closeButton {
        background: none;
        border: none;
        color: #333;
        font-size: 18px;
        cursor: pointer;
        text-align: right;
        display: block;
        margin-bottom: 20px;
        position: absolute;
        top: 0px;
        width: -moz-fit-content;
        width: fit-content;
        right: 0px;
    }
    .filterOptions.open {
    }

    .filterSection {
        width: 100% !important;
        overflow: unset;
    }

    .edventureBanner {
        border-radius: 0px 0px 20% 20%;
    }
    .addToCart {
        padding: 8px 18px;
    }
    .priceDis h3 {
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .edventureBanner div {
        flex-direction: column;
    }
    .edventureBanner {
        height: 390px;
    }
}
