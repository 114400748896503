.footer {
    color: black;
    background: white;
    padding: 50px 0px;

    /* border-top: 1px solid rgb(128 128 128); */
}

.footerSection {
    display: flex;
    flex-direction: column;
}
.footerBoxes {
    width: 70%;
}
.paymentDisplay {
    display: flex;
    align-items: center;
}
.footerHeader {
    /* width: 80%; */
    font-size: 20px;
}
.footerSection p {
    cursor: pointer;
    transition: 0.3s;
}
.footerSection p:hover {
    color: var(--main);
}
.footerSection h1 {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 20px;
}
.footerSection a {
    color: #0a142f;
    padding: 10px 0px;
    transition: 0.3s;
    font-weight: 450;
    font-size: 14px;
}
.footerSection a:hover {
    color: var(--main);
}
.footerDis {
    display: flex;
    justify-content: space-between;
}
.footerLogo {
    width: 20%;
}
.footerLogo img {
    width: 90px;
}
.socials {
    padding: 10px 0px;
}
.socials {
    display: flex;
    align-items: center;
    gap: 10px;
}
.footerClubs img {
    width: 55px;
}
.footerClubs {
    display: flex;
    align-items: center;
    gap: 8px;
}
.footerClubs p {
    font-weight: 700;
    height: 20px;
    font-size: 16px;
}
.footerDis span {
    font-size: 12px;
    color: #878787;
}
.privacyDis {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ececec;
    padding-top: 20px;
    margin-top: 20px;
    font-size: 14px;
}
.privacyDis p,
.privacyDis a,
.privacyDis div {
    color: #6e7484;
}

@media (max-width: 800px) {
    .footer h1 {
        font-size: 15px;
    }
    .footerDis {
        flex-direction: column;
    }
    .footerBoxes {
        width: 100%;
        padding-top: 20px;
    }
    .privacyDis {
        flex-direction: column-reverse;
        text-align: center;

        gap: 10px;
    }
    .footerClubs,
    .socials {
        justify-content: center;
    }
    .footerSection a,
    .footerSection h1 {
        text-align: center;
    }
    .footer h1 {
        padding-bottom: 10px;
    }
    .footerLogo {
        display: flex;
        width: 100%;
    }
    .footerLogo img {
        margin: auto;
    }
}
