.experienceBck {
    background-color: #fefff0;
    padding: 50px 0px;
}
.experienceHeader {
    text-align: center;
}
.experienceHeader h1 {
    color: var(--sub-main);
}
.experienceHeader p {
    font-weight: 500;
    font-size: 18px;
}
.experienceClub {
    display: flex;
    align-items: center;
    background-color: #ea5443;
    justify-content: center;
    gap: 10px;
    padding: 5px;
    border-radius: 8px;
}
.changeColor {
    background-color: var(--sub-main);
}
.experienceClub h2 {
    color: white;
}
.experienceClub img {
    height: 40px;
}
.experienceDis {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    column-gap: 20px;
    row-gap: 20px;
    padding-top: 20px;
}
.experienceClubImg {
    width: 100%;
    padding: 20px;
    height: 300px;
}
.experienceBox {
    background-color: white;
    border-radius: 7px;
    border: 1px solid #dfdfdf;
}
.exoerienceList div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    column-gap: 10px;
    /* row-gap: 20px; */

    min-height: 310px;
}
.experienceClub p {
    color: white;
    font-weight: 500;
}
.exoerienceList {
    padding: 20px;
    padding-top: 0px;
}
.exoerienceList p {
    font-weight: 700;
}
.exoerienceList ul {
    text-align: left;
    list-style: disc;
}
.exoerienceList li {
    font-weight: 700;
    font-size: 14px !important;
    width: 80%;
    margin: auto;
    padding-bottom: 12px;
}
.exoerienceList a {
    justify-content: center;
}
@media (max-width: 800px) {
    .experienceDis {
        grid-template-columns: repeat(1, 1fr);
    }
    .experienceClubImg {
        height: auto;
    }
}
