.shoppingCart {
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 20px;
    padding-top: 100px;
}

.shoppingCart ul {
    list-style-type: none;
    padding: 0;
}

.shoppingCart li {
    margin-bottom: 5px;
}
