.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999999999;
}
.closeButton {
    position: absolute;
    top: -5px;
    right: -5px;
    width: fit-content;
    border: none;
    font-size: 24px;
    background-color: unset;
    color: black;
    cursor: pointer;
    display: none;
}
.thankYou {
    display: none;
    padding-top: 20px;
}

.popupContainer {
    background-color: white;
    border-radius: 30px;
    overflow: hidden;
    max-width: 600px;
    width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    padding: 25px;
    position: relative;
}

.videoContainer {
    background-color: #640d5f;
    padding: 20px 0px;
    overflow: hidden;
    border-radius: 20px;
}

.videoContainer img {
    height: 230px;
}

.contentContainer {
    padding: 20px;
}

.giftBadge {
    background-color: #ffd700;
    color: #000;
    padding: 5px 10px;
    border-radius: 20px;
    display: inline-block;
    font-weight: bold;
    /* margin-bottom: 10px; */
}
.error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}
.heading {
    font-size: 24px;
    margin: 10px 0;
    color: #520c4d;
}

.description {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    /* padding: 10px; */
    border: 1px solid #640d5f;
    border-radius: 5px;
    flex: 1;
    width: 70%;
    margin: auto;
    border-radius: 50px;
}

.input {
    width: 70%;
    border: unset;
    margin-right: 10px;
    background-color: unset;
}
.input:focus-visible {
    outline: none !important;
}

.button {
    background-color: #520c4d;
    color: white;
    border: none;
    border-radius: 50px;

    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    width: 30%;
}

.button:hover {
    background-color: #3e093b;
}

.terms {
    font-size: 12px;
    color: #666;
    padding-top: 10px;
}
@media (max-width: 768px) {
    .form {
        width: 100%;
    }
    .contentContainer {
        padding: 20px 0px;
    }
    .button {
        width: 40%;
    }
}
