.clubsExplore {
    /* background: #fff7ef; */

    margin-top: -150px;
    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;
}
.true {
    background-image: url('../../../public/image/cloud.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.clubsTree {
    position: absolute;
    top: 0px;
    left: 50px;
}
.clubsTreeTwo {
    position: absolute;
    top: 0px;
    right: 50px;
}
.clubsBox {
    border-radius: 16px;
    border: 1px solid #efefef;
    background: rgba(234, 84, 67, 0.2);
    padding-top: 80px;
    position: relative;
    text-align: center;
}
.bo {
    position: absolute;
    left: -20px;
    width: 50px;
    top: 70px;
}
.clubsHeader p {
    font-weight: 600;
}
.clubsHeader h4 {
    margin-top: 0px;
    padding-top: 10px;
}
.firstBox span {
    color: #ff7628;
}
.firstBox {
    text-align: center;
}
.clubs {
    padding-top: 10px;
}
.clubsBox div {
    background-color: var(--main);
    border-radius: 100% 100% 36px 36px;
    display: flex;
    align-items: center;
    flex-direction: column;

    padding-bottom: 25px;
    color: white;
    position: relative;
    padding: 50px 20px 20px 20px;
}
.zooClub div {
    background-color: #ff7628;
}
.clubs {
    display: flex;
    align-items: center;
    gap: 5px;
}
.clubsBox div span {
    background-color: #fbff4b;
    font-size: 12px;
    color: #514f4e;
    padding: 2px 15px;
    border-radius: 50px;
    position: relative;
    top: -10px;
    z-index: 2;
    font-weight: bold;
}
.profile {
    position: absolute;
    top: -72px;
    height: 120px;
}
.clubsBox p {
    padding: 20px;
    padding-top: 0px;
}
.clubsBox h3 {
    font-size: 24px;
    margin-top: 7px;
    margin-bottom: 10px;
}
.clubsExplore h1 {
    padding-top: 30px;
}
@media (max-width: 1024px) {
    .clubsExplore {
        padding-top: 150px;
    }
}
@media (max-width: 800px) {
    .clubs {
        flex-direction: column;
        gap: 10px;
    }
    .clubs {
        padding-top: 0px !important;
    }
    .clubsExplore h1 {
        padding-top: 0px;
    }
    .clubsExplore {
        margin-top: 0px;
    }
    .clubsTreeTwo {
        top: 50px;
    }
}
