.banner {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
}
.banner div:nth-child(1) {
    width: 45%;
    background-color: #ff5500;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 50px;
    background-image: url('../../../public/image/sno.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.learnZoo {
    position: relative;
}
.bell {
    position: absolute;
    right: 0px;
    top: 50%;
}
.fal1 {
    width: 50px;
    left: 0px;
    top: 50px;
    position: absolute;
}
.fal2 {
    width: 50px;
    right: 0px;
    top: 30px;
    position: absolute;
}
.fal3 {
    width: 30px;
    left: -50px;
    top: 250px;
    position: absolute;
}
.fal4 {
    width: 25px;
    left: 0px;
    top: 380px;
    position: absolute;
}
.tabsZoo {
    position: relative;
}
.fal11 {
    width: 50px;
    left: -50px;
    top: 150px;
    position: absolute;
}
.fal22 {
    width: 50px;
    right: -50px;
    top: 180px;
    position: absolute;
}
.fal33 {
    width: 30px;
    right: -70px;
    top: 250px;
    position: absolute;
}
.bell2 {
    position: absolute;
    left: -90px;
    top: 50%;
}
.fal44 {
    width: 30px;
    left: -70px;
    top: 70%;
    position: absolute;
}
.fal55 {
    width: 40px;
    left: -70px;
    bottom: 0px;
    position: absolute;
}
.fal66 {
    width: 40px;
    right: -70px;
    bottom: 0px;
    position: absolute;
}
.fal77 {
    width: 25px;
    right: -100px;
    bottom: 50px;
    position: absolute;
}
.trees {
    background-image: url('../../../public/image/treess.png');
    background-repeat: no-repeat;
    background-size: cover;
    /* padding-top: 150px; */
    width: 100%;
    margin-top: 50px;
    position: relative;
}
.gift1 {
    position: absolute;
    left: 0px;
    top: 50%;
    width: 150px;
}
.gift2 {
    position: absolute;
    left: 50px;
    top: 80%;
    width: 100px;
}
.gift3 {
    position: absolute;
    right: 0px;
    top: 50%;
    width: 150px;
}
.gift4 {
    position: absolute;
    right: 50px;
    top: 80%;
    width: 100px;
}
.banner div:nth-child(2) {
    display: flex;
    align-items: center;
    width: 55%;
}
.banner h1 {
    font-size: 38px;
    color: white;
    position: relative;

    z-index: 999;
}
.banner p {
    color: white;
    font-size: 15px;
    padding: 20px 0px;
    position: relative;
    z-index: 999;
}
.banner img {
    height: 400px;
    border-radius: 20px;
}
.buttonDis {
    display: flex;
    align-items: center;
}
.buttonDis a {
    margin: 5px;
}
.cubIcon {
    height: 50px !important;
    width: 50px !important;
}
.zooTitle {
    color: #4da84d;
}
.zooPos {
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 200px !important;
}
@media (max-width: 1024px) {
    .banner {
        flex-direction: column;
    }
    .banner div {
        width: 100% !important;
    }
    .banner div:nth-child(2) {
        justify-content: center;
    }
}
@media (max-width: 800px) {
    .banner div:nth-child(2) {
        justify-content: center;
        flex-direction: column;
    }
    .banner div:nth-child(1) {
        padding: 20px;
    }
    .banner img {
        width: 100%;
        height: auto;
    }
    .banner div:nth-child(1) {
        border-radius: 20px 20px 0px 0px;
    }
    .banner img {
        border-radius: 0px 0px 20px 20px;
    }
    .buttonDis img {
        border-radius: 50px;
    }
    .bell {
        right: -18px;
        top: 40%;
        width: 60px;
    }
    .fal66 {
        width: 40px;
        right: 0px;
        bottom: 0px;
        position: absolute;
    }
    .fal77 {
        width: 30px;
        right: 0px;
        bottom: 50px;
        position: absolute;
    }
    .fal22 {
        width: 40px;
        right: 0px;
        top: 150px;
        position: absolute;
    }
    .fal33 {
        width: 30px;
        right: 0px;
        top: 200px;
        position: absolute;
    }
    .fal11 {
        display: none;
    }
    .zooPos {
        width: 150px !important;
        height: auto !important;
    }
    .banner div:nth-child(1) {
        padding-bottom: 200px;
    }
}
