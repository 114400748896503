.bigBoxDis {
    display: grid;
    column-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
    padding: 50px 0px;
}
.bigBoxesLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
}
.labelTop img {
    height: 30px;
}
.bigBoxes {
    position: relative;
}
.labelTop {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    padding: 5px 10px;
    top: 10px;
    left: 10px;
    color: white;
    /* font-weight: bold; */
    border-radius: 15px;
}
.labelTop p {
    font-weight: bold;
}
.bigBoxesLabel span {
    background-color: #fbff4b;
    padding: 2px 5px;
    border-radius: 50px;
    font-weight: bold;
    color: black;
}
.bigBoxesContent h2 {
    font-size: 38px;
}
.mainImage {
    width: 100%;
    display: block;
}
.bigBoxesContent {
    /* padding-top: 50px; */
    padding: 20px 50px;
    margin-top: -50px;
    z-index: 1;
    position: relative;
    border-radius: 20px;
    color: white;
    text-align: center;
}
.changeCub {
    background-color: #ea5443;
}
.changeZoo {
    background-color: #ff7628;
}
.bigBoxButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 20px;
}
.bigBoxButton a {
    display: flex;
    align-items: center;
    border: 1px solid #ffffff;
    border-radius: 50px;
    padding: 10px;
    gap: 10px;
    transition: 0.3s;
    font-weight: bold;
    background-color: hsl(0, 0%, 100%, 0.2);
}
.bigBoxButton a:nth-child(1) {
    background-color: #fbff4b;
    color: black;
    border: 1px solid #fbff4b;
}
.bigBoxButton a:hover {
    background-color: #0b9c7f;
    border: 1px solid #0b9c7f;
}
.bigBoxesContent p {
    padding-top: 10px;
    width: 70%;
    margin: auto;
}
.bigBoxButton a {
    color: white;
}
.bigBoxButton a:nth-child(1) img {
    filter: invert(0%);
}
.bigBoxButton a:nth-child(2) img {
    filter: invert(100%);
}
@media (max-width: 800px) {
    .bigBoxDis {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 10px;
    }
    .bigBoxesContent {
        padding: 20px;
    }
    .bigBoxesLabel span {
        font-size: 14px;
    }
    .bigBoxesContent h2 {
        font-size: 24px;
    }
    .bigBoxesContent p {
        width: 100%;
    }
    .bigBoxesContent p {
        display: none;
    }
}
