@import url('https://fonts.googleapis.com/css2?family=Acme&family=Barlow:wght@700&family=Cuprum:wght@600&family=Figtree:ital,wght@0,300..900;1,300..900&family=Lato:wght@300;400&family=Libre+Franklin:wght@700&family=Montserrat:wght@300;600&family=Ubuntu+Mono:wght@700&family=Viga&display=swap');
* {
    box-sizing: border-box;
    font-family: 'Figtree', sans-serif;
}
.google-map-html {
    font-size: 0.2px;
}

:root {
    --main: #d9493a;
    --sub-main: #ff7628;
    /* --main: #581845;
  --main: #001f3f; */
    --light-main: #fff0d8;
    --black: #000;
    --white: #fff;
    --radius: 30px;
}

p,
h1,
h2 {
    margin: 0px;
    padding: 0px;
}
p {
    font-weight: 600;
    font-weight: 300;
}
ul li,
ol li {
    font-size: 16px !important;
    font-weight: 300;
    padding-bottom: 20px;
}
h1 {
    font-size: 38px;
}
body {
    padding: 0px;
    margin: 0px;
}
._form_23 {
    display: none;
}
.container {
    width: 1200px;
    margin: auto;
}
.subContainer {
    width: 800px;
    margin: auto;
    text-align: center;
}
.thirdContainer {
    width: 1000px;
    margin: auto;
    text-align: center;
}
ul,
ol {
    list-style: none;
    padding: 0px;
}
a {
    text-decoration: none;
    color: var(--black);
    /* padding: 20px 0px; */
}
/* .SubblyAppContainer button {
    display: none;
} */
.offer {
    background-color: var(--main);
    text-align: center;
    padding: 15px 50px;
    border-radius: 0px 0px 100px 100px;
    color: white;
    margin-bottom: 6px;
    width: 1200px;
    margin: auto;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 99999;
    gap: 50px;
}
.offer p {
    width: 60%;
    text-align: center;
}
.smallProductsDis {
    padding-top: 20px;
}
.smallProduct div:nth-child(1) {
    display: flex;
    flex-direction: column !important;
}
.smallProduct img {
    width: 100%;
    object-fit: contain;
    /* height: 10px; */
    margin: auto;
    display: flex;
}
.bigImage img {
    width: 100%;
    margin: 0px;
    object-fit: contain;
}
.offer a {
    text-decoration: underline;
    font-size: 12px;
    color: white;
    padding-left: 15px;
}
._form_19 {
    display: none;
}
.offer span {
    color: #fbff4b;
}
.pt50 {
    padding-top: 50px;
}
.pb50 {
    padding-bottom: 50px;
}
.grid4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    column-gap: 10px;
}
.allShopV .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    z-index: 99999;
}
.grid12 {
    display: grid;
    grid-template-columns: 2fr 3fr;
    width: 100%;
    column-gap: 5px;
    /* align-items: center; */
}
/* Add this to your stylesheet */

/* Example styling for step pages */
.toggle-page,
.siblings-page,
.addon-page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; /* Set your background color */
    transition: opacity 0.5s, transform 0.5s;
}
.swiper-button-prev:after {
    background-color: white !important;
    color: #d1d1d1;
    font-size: 20px;
    padding: 10px 15px;
    font-weight: bold;
    border-radius: 50%;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
}
.swiper-button-next:after {
    background-color: white !important;
    color: #d1d1d1;
    font-size: 20px;
    padding: 10px 15px;
    font-weight: bold;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);

    border-radius: 50%;
}
.swiper-button-next {
    right: 20px !important;
    top: 50% !important;
}
.pt20 {
    padding-top: 10px;
}
.swiper-button-prev {
    left: 20px !important;
    top: 50% !important;
}
.pb15 {
    padding: 10px 0px;
}
.ptb5 {
    padding: 5px 0px;
}
._form_25 {
    display: none;
}
._form_27 {
    display: none;
}
.allShopV .swiper-pagination-bullet-active {
    background-color: var(--sub-main);
}
.productCar
    .swiper.swiper-initialized.swiper-horizontal.swiper-watch-progress.mySwiper.smallProduct.swiper-backface-hidden.swiper-thumbs {
    width: 100%;
}
.productCar
    .swiper.swiper-initialized.swiper-horizontal.bigImage.swiper-backface-hidden {
    width: 100%;
}
.productCar .swiper-wrapper {
    gap: 10px;
}
.productCar
    .swiper.swiper-initialized.swiper-horizontal.swiper-watch-progress.mySwiper.smallProduct.swiper-backface-hidden.swiper-thumbs {
    width: 25%;
}
.offerTwo p {
    text-align: center !important;
    font-weight: 600;
}
._form_29 {
    display: none;
}
@media (max-width: 1024px) {
    .container,
    .subContainer,
    .thirdContainer {
        width: 100%;

        padding-left: 20px;
        padding-right: 20px;
    }

    .offer {
        width: 90%;
        padding: 0px 20px;
    }
}
@media (max-width: 800px) {
    .grid4 {
        grid-template-columns: repeat(1, 1fr);
        text-align: center;
        row-gap: 30px;
    }
    .foo h1 {
        font-size: 32px;
    }
    .offerBck {
        /* background-color: var(--sub-main); */
        padding-bottom: 10px;
    }
    .offer {
        margin-bottom: 0px;
        gap: 10px;
        padding: 10px;
    }
    .productCar
        .swiper.swiper-initialized.swiper-horizontal.swiper-watch-progress.mySwiper.smallProduct.swiper-backface-hidden.swiper-thumbs {
        width: 100%;
    }
    .bigImage img {
        height: 200px;
    }
    .smallProduct img {
        height: 70px;
    }
    .offer {
        flex-direction: column;
        border-radius: 0px 0px 20px 20px;
    }
    .offer p {
        width: 100%;
        text-align: center;
    }
    .smallProduct div:nth-child(1) {
        flex-direction: row !important;
    }
    .swiper.swiper-initialized.swiper-horizontal.swiper-watch-progress.mySwiper.smallProduct.swiper-backface-hidden.swiper-thumbs {
        width: 100%;
    }
}
