.learnDis {
    display: flex;
    /* align-items: center; */
    gap: 50px;
    width: 90%;
    margin: auto;
}
.learnImage {
    width: 50%;
}
.learnImage img {
    width: 100%;
}
.learnContent {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.learnContent div {
    display: flex;
    align-items: flex-start;
}
.learnContent p {
    padding: 20px 0px;
    font-weight: 700;
}
.learnContent div p {
    padding-top: 0px !important;
    padding-bottom: 12px !important;
}
.learnContent a {
    display: block;
    margin-top: 10px;
}
.learnContent h2 {
    font-size: 30px;
}
.learnContent img {
    margin-right: 5px;
    height: 20px;
}
@media (max-width: 1024px) {
}
@media (max-width: 800px) {
    .learnDis {
        flex-direction: column;
    }
    .learnContent,
    .learnImage {
        width: 100%;
    }
}
