.storeBanner {
    position: relative;
}
.storeBanner h2 {
    color: black;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    right: 0;
    display: flex;
    font-size: 56px;
    justify-content: center;
    align-items: center;
    bottom: 0;
    padding-bottom: 50px;
}
.dicount span {
    text-decoration: line-through;
    color: red;
    text-align: left;
    margin: 0px;
}
.dicount p {
    padding-bottom: 0px !important;
    font-size: 20px !important;
}
.dicount i {
    padding-bottom: 20px;
}
.dicount {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: unset !important;
    width: fit-content;
    margin: 0px auto;
    padding: 0px !important;
    text-align: center;
}
/* .storeNavBck {
    display: none;
} */
.customer {
    font-size: 14px;
    text-align: center;
    padding: 10px 0px;
}
.customer p {
    font-weight: bold;
}
.storeDis {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    column-gap: 20px;
    row-gap: 20px;
    padding-bottom: 50px;
}
.storeDis div {
    position: relative;
}
.plansBox h2 {
    text-align: center;
    padding-bottom: 50px;
    font-size: 18px;
    color: #f68c1f;
}
.storeEgg h1 {
    padding-bottom: 0px !important;
    color: #51b0bb !important;
}
.egg {
    position: absolute;
    height: 120px;
    width: 120px;
    top: -100px;
    left: -20px;
}
.storeNav div {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    column-gap: 20px;
    row-gap: 20px;
    padding: 30px 0px;
    text-align: center;
}
.storeNavBck {
    background-color: hsl(0, 0%, 100%, 0.5);
    position: sticky;
    top: 0;
    z-index: 9;
}
.store {
    position: relative;
}
.storeNav p {
    border: 2px solid #258d8f;
    color: #258d8f;
    padding: 5px;
    border-radius: 8px;
    font-weight: bold;
    margin: 0px;
    font-size: 14px;
    cursor: pointer;
}
.storeDis img {
    /* width: 100%; */
    height: 200px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin: auto;
}
.storeDis div {
    padding: 20px;
    text-align: center;
    border-radius: 20px;
    background-color: #e3f3ec;
}
.storeDis h3 {
    font-weight: 400;
    margin: 0px;
    font-size: 26px;
    padding: 20px 0px;
}

.storeDis p {
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 20px;
}
.storeDis i {
    color: #9ba4a0;
    font-weight: 500;
}
.storeDis a {
    border-radius: 8px;
    border: 2px solid #258d99;
    color: #258d99;
    padding: 8px 18px;
    background-color: white;
    font-weight: bold;
    font-size: 18px;
}
.plansBox h1 {
    text-align: center;
    color: var(--main);
    padding: 50px 0px;
    border-top: 2px solid var(--main);
    font-weight: bold;
}
.storeTwo h1 {
    color: var(--sub-main) !important;
    border-top: 2px solid var(--sub-main) !important;
}
.storeThree h1 {
    color: #f0c161 !important;
    border-top: 2px solid #f0c161 !important;
}
.storeFour h1 {
    color: #f6e7a6 !important;
    border-top: 2px solid #f6e7a6 !important;
}
.storeFive h1 {
    color: #a3d8c1 !important;
    border-top: 2px solid #a3d8c1 !important;
}
.storeSix h1 {
    color: #258d8f !important;
    border-top: 2px solid #258d8f !important;
}

.mobileNav {
    display: none;
    padding-bottom: 20px;
}
.mobileDisplay {
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;
}

.mobileList {
    display: flex;
    align-items: center;
    width: 100%;
}
.mobileList li {
    list-style: none;
}
.mobileDisplay a {
    z-index: 3;
}
.hamburger-menu {
    width: 20%;
    z-index: 3;
}
.mobileDisplay a {
    color: white;
}
.mobileNav ul {
    text-align: left;
}
.mobileMenuContainer {
    background-color: white;
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: -1200%;

    transition: all 0.9s ease-in-out;
    -webkit-animation: movedown 0.9s linear forwards;
    -moz-animation: movedown 0.9s linear forwards;
    -ms-animation: movedown 0.9s linear forwards;
    -o-animation: movedown 0.9s linear forwards;
    animation: movedown 0.9s linear forwards;
    color: white;
    z-index: 999999;
    padding: 10px 20px;
}

.mobileMenuContainer.opened {
    top: 0px;
    transition: all 0.3s ease-in-out;
    overflow-y: hidden;
}
.mobileMenuContainer ul {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
}
.mobileMenuContainer a {
    font-size: 18px;
    font-weight: 400;
    color: white;
    text-align: left;
    animation: slidein 0.5s linear forwards;
    transition: all 0.2s ease-in-out;
}
.mobileHeaderData {
    display: flex;
    align-items: center;
    font-size: 13px;
    width: 100%;
}
.mobileBtn {
    display: block;
    text-align: center !important;
}

.hamburgerMenu {
    border-radius: var(--radius-input);
    color: var(--sub-main);
    margin-left: 5px;
    font-size: 20px;
    border-radius: var(--radius-input);
    outline: none;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.5s;
    z-index: 4;
    width: 100%;
}
.hamburgerMenu p {
    background-color: #f9cc0b;
    color: white;
    width: 100%;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    color: black;
    cursor: pointer;
    border-radius: 8px;
}
.mobSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    column-gap: 10px;
    row-gap: 10px;
}
.mobSection img {
    width: 100%;
    height: 110px;
    object-position: top;
    object-fit: cover;
    display: block;
}
.sectionBox div {
    /* background-color: var(--main); */
    padding: 10px;
    text-align: center;
}
.pred {
    background-color: #258d8f;
}
.afr {
    background-color: var(--main);
}
.rain {
    background-color: #ff8c4b;
}
.fly {
    background-color: #ec6e55;
}
.ocean {
    background-color: #258d8f;
}
.sectionBox h3 {
    margin: 0px;
    font-size: 14px;
}
.sectionBox p {
    font-size: 12px;
}
.mobileMenuContainer h2 {
    color: var(--main);
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    padding: 20px 0px;
}
.sectionCont div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
}
.sectionCont img {
    height: 10px;
    width: 10px;
    filter: invert(100%);
}
.closeMob {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 40px;
}
.offerBtn span {
    background-color: #258d8f;
    color: white;
    padding: 5px 20px;
    border-radius: 8px;
    cursor: pointer;
}
.offerBtn p {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    font-weight: 700;
}
.offerBtn div {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    font-weight: 700;
}
.stock {
    font-size: 16px !important;
    color: red !important;
}
.offerBtn {
    padding: 20px 0px;
    padding-top: 30px;
    background-color: #fef6ec;
}

.bigBoxDis {
    display: grid;
    column-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
    padding: 50px 0px;
    width: 70%;
    margin: auto;
}
.bigBoxesLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
}
.labelTop img {
    height: 30px;
}
.bigBoxes {
    position: relative;
    padding: 20px;
    padding-bottom: 0px;
}
.labelTop {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    padding: 5px 10px;
    top: 10px;
    left: 10px;
    color: white;
    /* font-weight: bold; */
    border-radius: 15px;
}
.labelTop p {
    font-weight: bold;
}
.bigBoxesLabel span {
    background-color: #fbff4b;
    padding: 2px 5px;
    border-radius: 50px;
    font-weight: bold;
    color: black;
}
.bigBoxesContent h2 {
    font-size: 32px;

    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Adjust the values as needed */
}
.bigBoxesContent h3 {
    margin: 0px;
}
.changeCub h3,
.changeZoo h3 {
    font-size: 32px;
}
.changeCub h3 {
    color: #eb5442;
}
.changeZoo h3 {
    color: #288d8d;
}
.shopDif {
    background-color: #eb5442;
    color: white;
    border-radius: 50px;
}
.mainImage {
    width: 100%;

    display: block;
}
.bigBoxesContent {
    /* padding-top: 50px; */
    /* padding: 20px 50px; */

    z-index: 1;
    position: relative;
    border-radius: 20px;
    color: black;
    text-align: center;
}
.bigBoxesContent p {
    font-weight: 500;
    padding-top: 5px;
}
.bigBoxesContent p:nth-child(1) {
    color: #292d32;
    font-size: 18px;
}
.changeCub p:nth-child(3) {
    color: #db4332;
}
.changeZoo p:nth-child(3) {
    color: #008e90;
}
.changeCub {
    border-radius: 15px;
    /* padding: 20px; */
    padding-bottom: 0px;
}
.changeZoo {
    border-radius: 15px;
    /* padding: 20px; */
    padding-bottom: 0px;
}
.bigBoxes {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.bigBoxButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 10px;
}
.bigBoxButton a {
    display: flex;
    align-items: center;

    border-radius: 50px;
    padding: 10px 30px;
    gap: 10px;
    transition: 0.3s;
    font-weight: bold;
}

.bigBoxesContent p {
    /* padding-top: 10px; */
    width: 100%;
    font-weight: 600;
    margin: auto;
}
.bigBoxesContent a {
    background-color: #ea5443;
    color: white;
    border: 1px solid transparent;
}

.bigBoxesContent a:hover {
    border: 1px solid #ea5443;
    background-color: white;
    color: #ea5443;
}

.tabsDis {
    display: flex;
    align-items: center;

    width: 562px;
    margin: auto;
    text-align: center;
    justify-content: center;
    background: #e3f3ec;
    border-radius: 70px;
    position: relative;
}

.tabsDis p {
    padding: 10px;
    border-radius: 50px;
    color: black;
    cursor: pointer;
    position: relative;
    z-index: 9998;
}
.tabsDis p:nth-child(1) {
    width: 20%;
}
.tabsDis p:nth-child(2) {
    width: 40%;
}
.tabsDis p:nth-child(3) {
    width: 40%;
}
.tabsDis p {
    font-weight: 600;
}
.spanTab {
    /* background-color: #ea5443; */
    position: absolute;
    height: 100%;
    border-radius: 50px;
    transition: all 0.3s ease-in;
}

.activeSpan {
    left: 0;
    width: 20%;
}

.activeSpanTwo {
    left: 20%;
    width: 40%;
}

.activeSpanThree {
    left: 60%;
    width: 40%;
}

.spanTab {
    background-color: #258d8f;
}
.tabDis {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.storeDis {
    padding-top: 40px;
}
.showTab {
    width: 90%;
    margin: auto;
}
.tabsDis .active {
    color: white;
}
.cubColor {
    color: #ff6a59;
    font-weight: 700 !important;
    margin: 0px;
}
.zooColor {
    color: #ff8c4b;
    font-weight: 700 !important;
    margin: 0px;
}
@media (max-width: 800px) {
    .storeDis {
        grid-template-columns: repeat(1, 1fr);
    }
    .bigBoxDis {
        width: 100%;
    }
    .storeBanner h2 {
        display: none;
    }
    .storeNav div {
        grid-template-columns: repeat(2, 1fr);
    }
    .mobileNav {
        display: block;
        padding-top: 30px;
    }
    .mobileClubs span {
        font-size: 14px;
    }
    .mobileClubs p {
        font-weight: 800;
        height: 20px;
    }
    /* .headerMobile {
        background-color: var(--sub-main);
    } */
    .mobileClubs img {
        width: 75px;
    }
    .storeNav {
        display: none;
    }
    .offerBtn p {
        flex-direction: column;
    }
    .bigBoxDis {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
    }

    .bigBoxesLabel span {
        font-size: 14px;
    }
    .bigBoxesContent h2 {
        font-size: 26px;
    }
    .changeCub h3,
    .changeZoo h3 {
        font-size: 26px;
    }
    .bigBoxesContent p {
        width: 100%;
        font-size: 14px;
    }
    .tabsDis {
        width: 90%;
        flex-direction: column;
        border-radius: 20px;
    }
    .tabsDis span,
    .tabsDis p {
        font-size: 14px;
    }
    .tabsDis img {
        width: 30px;
        height: 30px;
    }
    .tabsDis p:nth-child(1) {
        width: 100%;
    }
    .tabsDis p:nth-child(2) {
        width: 100%;
    }
    .tabsDis p:nth-child(3) {
        width: 100%;
    }
    .activeSpan {
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 33%;
    }

    .activeSpanTwo {
        left: 0;
        right: 0;
        top: 29%;
        width: 100%;
        height: 33%;
    }

    .activeSpanThree {
        left: 0;
        right: 0;
        top: 66%;
        width: 100%;
        height: 33%;
    }
    .bigBoxesContent p:nth-child(1) {
        font-size: 16px;
    }
}
