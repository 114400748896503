.empowerBanner {
    position: relative;
    padding-top: 20px;
}
.empowerPosition {
    position: absolute;
    color: white;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.empowerBanner p {
    background: #fbff4b;
    border-radius: 50px;
    color: black;
    width: fit-content;
    font-weight: 600;
    margin: 0 auto;
    padding: 10px 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
}
.empowerBanner h1 {
    padding-bottom: 10px;
}
.empowerBanner p:hover {
    background-color: white;
}
.empowerBanner img {
    width: 100%;
}
.empowerMinds {
    padding: 50px 0px;
}
.empowerBanner p img {
    height: 20px;
    width: 20px;
}

.emopwerKids {
    display: flex;
    gap: 40px;
    align-items: center;
}
.emopwerKids div {
    width: 50%;
}
.emopwerKids img {
    width: 100%;
}
.empowerMinds h2 {
    font-size: 32px;
    padding-bottom: 20px;
}
.empowerMinds p {
    font-weight: 600;
}
.emopwerKids h2 {
    color: #ea5443;
    padding-bottom: 20px;
}
.emopwerKids p {
    font-weight: 600;
}
.empowerGrants p {
    padding-bottom: 20px;
    font-weight: 600;
}
.empowerGrants h2 {
    padding-bottom: 20px;
}
.empowerCircle {
    display: flex;
    align-items: center;
    gap: 50px;
}
.reverse {
    flex-direction: row-reverse;
}
.circleImg {
    width: 30%;
}
.circeTxt {
    width: 70%;
}
.circeTxt h2 {
    color: #ea5443;
    padding-bottom: 20px;
}
.circleImg img {
    width: 100%;
}
.empowerGrants {
    padding-top: 50px;
}
.empowerBottom {
    padding-top: 50px;
}
.applicationSteps {
    display: flex;
    align-items: center;
    gap: 20px;
}
.application {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.application h3 {
    margin: 0px;
    padding-bottom: 10px;
}
.application {
    padding: 50px 0px;
}
.empowerApplication {
    padding-top: 50px;
}
.empowerApplication p {
    font-weight: 600;
}
.empowerForm {
    background-color: #fff7ef;
    padding: 50px 0px;
}
.empowerForm h1 {
    font-size: 32px;
}
.empowerForm h1 span {
    color: #ea5443;
}

.formContainer {
    width: 100%;
    margin: auto;
    /* padding: 20px; */
    /* border: 1px solid #ccc; */
    border-radius: 10px;
}

.formRow {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.formGroup {
    flex: 1;
    min-width: 45%;
    margin: 0 10px 10px 0;
}

label {
    display: block;
    margin-bottom: 5px;
}

input,
textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #969696;
}
textarea {
    max-width: 100%;
    min-height: 120px;
    max-height: 200px;
}
.error {
    color: red;
    font-size: 12px;
}

button {
    padding: 12px 20px;
    width: 100%;
    background-color: #d7403a;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
}

button:disabled {
    background-color: #ccc;
}
.formBottom {
    padding-top: 50px;
}
.formBottom p {
    font-weight: 600;
    padding-bottom: 20px;
}
.formBottom h2 {
    padding-bottom: 20px;
}
.desE {
    display: block;
}
.desM {
    display: none;
}
.empowerBtn {
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 5px;
    width: fit-content;
    margin: 0 auto;
}
.secondBtn {
    border: 1px solid #fbff4b;
    background: unset !important;
    color: white !important;
}
.submit {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 0px;
    gap: 10px;
    background-color: #b4f0db;
    color: #10533d;
    border-radius: 50px;
    font-weight: 500;
}
.submit img {
    width: 25px;
}
.secondBtn img {
    filter: invert(100%);
}
.empowerForm h1 {
    padding-bottom: 20px;
}
.empowerApplication {
    padding-bottom: 20px;
}
.tabs {
    display: flex;
    align-items: center;
    gap: 5px;
}
.tabs div {
    width: 33%;
    cursor: pointer;
    text-align: center;
    border: 1px solid #969696;
    border-radius: 8px;
    padding: 7px 5px;
    background-color: white;
}

.tabs p {
    /* width: 110px; */
    margin: auto;
    font-size: 14px;
    font-weight: 600;
}
.tabs .active {
    background-color: #d7403a;
    color: white;
    border-radius: 8px;
    padding: 7px;
}
@media (max-width: 800px) {
    .emopwerKids,
    .empowerCircle {
        flex-direction: column;
    }
    .circleImg,
    .circeTxt {
        width: 100%;
    }
    .empowerCircle {
        gap: 20px;
    }
    .circeTxt {
        padding-bottom: 50px;
    }
    .emopwerKids div {
        width: 100%;
    }
    .formRow {
        flex-direction: column;
    }
    textarea {
        width: 100% !important;
    }
    .desE {
        display: none;
    }
    .desM {
        display: block;
    }
    .empowerMinds h2 {
        font-size: 24px;
    }
    .submit {
        font-size: 13px;
        padding: 5px 5px;
        gap: 5px;
    }
}
